import {
  ComponentLayoutSliderGroup,
  ComponentContentSlider,
} from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { R, C, G } from "../../Workbench/Components"
import Slide from "./Slide"
import { ReactIdSwiperProps } from "react-id-swiper"
import Slider from "../../generic/Slider/Slider"

import "./SliderGroup.css"

const SliderGroup: FunctionComponent<Partial<ComponentLayoutSliderGroup>> = ({
  Sliders,
}) => {
  const sliderParams: ReactIdSwiperProps = {
    // containerClass: "", // this allows for the overflow
    spaceBetween: 0,
    // slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
      disableOnInteraction: true,
      delay: 3500,
    },
    effect: "slide",
    // fadeEffect: {
    //   crossFade: true
    // },
    speed: 500,
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return Sliders?.length && Sliders.length > 1 ? (
    <div>
      <Slider {...sliderParams}>
        {Sliders?.map((s, i) => (
          <div key={i} className="w-full">
            <Slide {...s} />
          </div>
        ))}
      </Slider>
    </div>
  ) : Sliders?.length === 1 ? (
    <div>
      <div className="w-full">
        <Slide {...Sliders[0]} />
      </div>
    </div>
  ) : null
}

export default SliderGroup

export const SliderGroupFragment = graphql`
  fragment SliderGroupFragment on StrapiGQLSource_ComponentLayoutSliderGroup {
    __typename
    id
    _id
    AnchorId
    Sliders {
      ...SlideFragment
    }
  }
`
