import React from "react"
import { FunctionComponent } from "react"

export enum PrefetchType {
    Image = 'image',
    Style = 'style',
    Font = 'font'
}

export interface PrefetchProps {
    href: string, 
    as?: PrefetchType
}

const Prefetch: FunctionComponent<PrefetchProps> = ({ href, as = PrefetchType.Image}) => {
    return <link rel="preload" href={href} as={as} />
}

export default Prefetch