import React, { FunctionComponent, useRef, useState } from "react"
import {
  Vehicle,
  ComponentLayoutVehicleSpecificationsCounter,
} from "../../../types/generated/strapi"

import CountUp from "./CountUp"
import { G, R, C } from "../../Workbench/Components"
import extractUnit from "../../../types/extractUnit"
import "./VehicleSpecsCounter.css"
import EngineIcon from "./EngineIcon"
import SettingsIcon from "./SettingsIcon"
import MileageIcon from "./MileageIcon"
import { graphql } from "gatsby"
import { useIntersect } from "./UseIntersect"

const VehicleSpecsCounter: FunctionComponent<ComponentLayoutVehicleSpecificationsCounter> = ({
  vehicle,
}) => {
  const [onScreen, setOnScreen] = useState<boolean>(false)

  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [node, entry] = useIntersect<HTMLDivElement>({
    threshold: buildThresholdArray(),
  })

  if (
    typeof entry !== "undefined" &&
    entry?.intersectionRatio > 0.01 &&
    onScreen === false
  ) {
    setOnScreen(true)
  }

  return (
    <div className="mt-xl" ref={node}>
      {(vehicle?.Torque || vehicle?.Acceleration || vehicle?.Power) &&
      onScreen ? (
        <div
          className="flex text-wb-color-white justify-center overflow-hidden"
          data-aos="fade"
        >
          {vehicle?.Torque ? (
            <div
              data-aos="fade-right"
              data-aos-delay="250"
              className={`${
                vehicle?.Acceleration || vehicle.Power ? "line-after" : ""
              }`}
            >
              <p className="spec pr-m">
                <CountUp SpecValue={vehicle?.Torque} Duration={10} />
                <span className="spec-sup">
                  {extractUnit(vehicle?.Torque)?.unit}
                </span>
              </p>
            </div>
          ) : null}
          {vehicle?.Acceleration ? (
            <div
              data-aos="fade"
              data-aos-delay="150"
              className={`${vehicle?.Power ? "line-after" : ""}`}
            >
              <p className="spec ml-s pr-xs">
                <CountUp SpecValue={vehicle?.Acceleration} Duration={500} />s
                <span className="spec-sup pr-xs md:pr-0">0-100 km/h</span>
              </p>
            </div>
          ) : null}
          {vehicle?.Power ? (
            <div data-aos="fade-left" data-aos-delay="200">
              <p className="spec ml-s pr-xs">
                <CountUp SpecValue={vehicle?.Power} Duration={10000} />
                <span className="spec-sup ">
                  {extractUnit(vehicle?.Power)?.unit}
                </span>
              </p>
            </div>
          ) : null}
        </div>
      ) : null}

      {vehicle?.EngineDescription || vehicle?.Gearbox || vehicle?.TopSpeed ? (
        <G>
          <div className="flex flex-col md:flex-row justify-center mt-l">
            {vehicle?.EngineDescription ? (
              <div
                className="flex md:justify-center"
                data-aos="fade"
                data-aos-delay="550"
              >
                <EngineIcon />
                <p className="flex flex-col justify-center wb2-type-copy ml-xs text-wb-color-white">
                  {vehicle?.EngineDescription}
                </p>
              </div>
            ) : null}
            {vehicle?.Gearbox ? (
              <div
                className="flex md:justify-center mt-xs md:mt-0 md:ml-s"
                data-aos="fade"
                data-aos-delay="500"
              >
                <SettingsIcon />
                <p className="flex flex-col justify-center wb2-type-copy ml-xs text-wb-color-white">
                  {vehicle?.Gearbox}
                </p>
              </div>
            ) : null}
            {vehicle?.TopSpeed ? (
              <div
                className="flex md:justify-center mt-xs md:mt-0 md:ml-s"
                data-aos="fade"
                data-aos-delay="550"
              >
                <MileageIcon />
                <p className="flex flex-col justify-center wb2-type-copy ml-xs text-wb-color-white">
                  {vehicle?.TopSpeed}
                </p>
              </div>
            ) : null}
          </div>
        </G>
      ) : null}
    </div>
  )
}

export default VehicleSpecsCounter

export const VehicleSpecsCounterFragment = graphql`
  fragment VehicleSpecsCounterFragment on StrapiGQLSource_ComponentLayoutVehicleSpecificationsCounter {
    __typename
    id
    _id
    AnchorId
    vehicle {
      __typename
      _id
      id
      Power
      Price
      TopSpeed
      Torque
      Acceleration
      Engine
      EngineDescription
      Gearbox
    }
  }
`
