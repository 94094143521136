/**
 * Validate a given value against
 * @param match Regex to validate agains
 * @param value value to validate
 * @param minLength min length of value
 * @param maxLength max length of value
 */
const isFieldValid = (
  match: RegExp,
  value?: any,
  minLength = 1,
  maxLength = 200
) => {
  const trimmedValue = value?.toString()

  return (
    typeof value !== "undefined" &&
    value !== null &&
    trimmedValue.match(match) !== null &&
    trimmedValue.match(match).length > 0 &&
    trimmedValue.length >= minLength &&
    trimmedValue.length <= maxLength
  )
}

export default isFieldValid

/**
 * Validation match regexes
 */
export const matchers = {
  any: /./g,
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
  phone: /^[\+\d]\d*/g,
  postalCode: /\d*/g,
  marketingConsent: /./g,
}
