import { FunctionComponent } from "react"
import { CourseType } from "../../../types/generated/strapi"
import ResponsiveSlider from "../../generic/Slider/ResponsiveSlider"
import CourseCard from "./CourseCard"
import React from "react"
import { sortedCourseTypesByLevel } from "../BookingForm/stepOneConverisons"

export type CourseListingProps = {
  data?: CourseType[]
}

const CourseListing: FunctionComponent<CourseListingProps> = ({ data }) => {


  const sortedCourses = sortedCourseTypesByLevel(data)
  return sortedCourses ? (
    <div>
      <ResponsiveSlider
        title="Available Courses"
        subtitle="AMG Driving Academy"
        useTitleAnimation={true}
        slides={sortedCourses?.filter(c => c.IsActive === true)?.map((c, i) => (
          <div
            key={i}
            data-aos="fade-up"
            data-aos-delay={i * 100}
            className="w-full"
          >
            <CourseCard {...c} animationStartDelay={i * 100} />
          </div>
        ))}
        autoResize={false}
      />
    </div>
  ) : null
}

export default CourseListing
