import { FunctionComponent } from "react"
import React from "react"
import { Titleship } from "../../../types/generated/webApi"
import { G, R, C } from "../../Workbench/Components"
import RadioGroup from "../../generic/Form/RadioGroup"
import InputBox from "../../generic/Form/InputBox"
import { ParagraphGroup } from "../../sections/Paragraph/ParagraphGroup"
import LoadingState from "../../generic/Form/LoadingState"
import isFieldValid, { matchers } from "../../../utils/isFieldValid"

export interface StepTwoProps {
  value: StepTwoDetails
  shouldShowErrors?: boolean
  onValueChange: (newValue?: StepTwoDetails) => void
  loadingState?: LoadingState
}

export interface StepTwoDetails {
  title?: Titleship
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  isValid?: boolean
}

export interface FormData extends StepTwoDetails {
  isValid?: boolean
}

const StepTwo: FunctionComponent<StepTwoProps> = ({
  value,
  shouldShowErrors,
  onValueChange,
  loadingState = LoadingState.Initial,
}) => {
  const isDisabled = loadingState === LoadingState.IsLoading

  const showFieldError = (
    match: RegExp,
    value?: any,
    minLength?: number,
    maxLength?: number
  ) => {
    return (
      shouldShowErrors === true &&
      !isFieldValid(match, value, minLength, maxLength)
    )
  }

  const handleFieldChange = (newValue?: StepTwoDetails) => {
    const validations = [
      isFieldValid(matchers.any, newValue?.title),
      isFieldValid(matchers.any, newValue?.firstName),
      isFieldValid(matchers.any, newValue?.lastName),
      isFieldValid(matchers.email, newValue?.email),
      isFieldValid(matchers.phone, newValue?.phone, 10, 14),
    ]

    const isValid =
      validations.filter(isFieldValid => isFieldValid !== true).length === 0

    onValueChange({ ...newValue, isValid })
  }

  return (
    <G>
      <R>
        <C desktopSize={12} tabletSize={12} mobileSize={12} className="mt-xs">
          <label className="block text-wb-color-white text-sm font-demi">
            Title
            <span className="text-wb-color-amg">*</span>
          </label>
          <RadioGroup
            onChange={(title: Titleship) => {
              // Pass title as Enum(number)
              handleFieldChange({ ...value, title })
            }}
            value={value.title}
            isHorizontal={true}
            options={[
              { name: "Mr", label: "Mr", value: Titleship.Mr },
              { name: "Mrs", label: "Mrs", value: Titleship.Mrs },
              { name: "Ms", label: "Ms", value: Titleship.Ms },
              { name: "Dr", label: "Dr", value: Titleship.Dr },
              {
                name: "Prof",
                label: "Prof",
                value: Titleship.Prof,
              },
            ]}
            error="Please select an option"
            hasError={showFieldError(matchers.any, value.title)}
            isDisabled={isDisabled}
          />
        </C>
      </R>

      <R>
        <C desktopSize={4} tabletSize={6} mobileSize={12} className="mt-xs">
          <InputBox
            label="First Name"
            name="FirstName"
            type="text"
            value={value.firstName}
            onChange={(firstName?: string) =>
              handleFieldChange({ ...value, firstName })
            }
            isRequired={true}
            error="Please enter First Name"
            hasError={showFieldError(matchers.any, value.firstName)}
            isDisabled={isDisabled}
          />
        </C>

        <C desktopSize={4} tabletSize={6} mobileSize={12} className="mt-xs">
          <InputBox
            label="Last Name"
            name="LastName"
            type="text"
            value={value.lastName}
            onChange={(lastName?: string) =>
              handleFieldChange({ ...value, lastName })
            }
            isRequired={true}
            error="Please enter Last Name"
            hasError={showFieldError(matchers.any, value.lastName)}
            isDisabled={isDisabled}
          />
        </C>
      </R>

      <R>
        <C desktopSize={4} tabletSize={6} mobileSize={12} className="mt-xs">
          <InputBox
            label="Email"
            name="Email"
            type="email"
            value={value.email}
            onChange={(email?: string) =>
              handleFieldChange({ ...value, email })
            }
            isRequired={true}
            error="Please enter Email"
            hasError={showFieldError(matchers.email, value.email)}
            isDisabled={isDisabled}
          />
        </C>

        <C desktopSize={4} tabletSize={6} mobileSize={12} className="mt-xs">
          <InputBox
            label="Contact Number"
            name="Tel"
            type="tel"
            value={value.phone}
            onChange={(phone?: string) =>
              handleFieldChange({ ...value, phone })
            }
            isRequired={true}
            error="Please enter Contact Number"
            hasError={showFieldError(matchers.phone, value.phone, 8, 13)}
            isDisabled={isDisabled}
          />
        </C>
      </R>
    </G>
  )
}

export default StepTwo
