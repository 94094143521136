import CourseInformation from "../../sections/CourseInformation/CourseInformation"
import {
  ComponentLayoutCourseInformation,
  ComponentLayoutVehicleOverview,
  ComponentLayoutLinkBlockGroup,
  ComponentLayoutSliderGroup,
  ComponentLayoutDiagonalSlider,
  ComponentLayoutParagraph,
  ComponentLayoutTwoColumnImageFirst,
  ComponentLayoutTwoColumnTextFirst,
  ComponentLayoutTwoColumnTextOnly,
  ComponentLayoutImageGroup,
  ComponentLayoutTabs,
  ComponentLayoutImageGrid,
  ComponentLayoutVoucherForm,
  ComponentLayoutContactForm,
  ComponentLayoutBookingForm,
  ComponentLayoutCtaGroup,
  ComponentLayoutVehicleSpecificationsCounter,
} from "../../../types/generated/strapi"
import React, { ReactComponentElement } from "react"
import CourseListingLayout from "../CourseListingLayout/CourseLisitingLayout"
import VehicleOverview from "../../sections/VehicleOverview/VehicleOverview"
import LinkBlockGroup from "../../sections/LinkBlockGroup/LinkBlockGroup"
import StrapiComponent from "../../../types/StrapiComponent"
import SliderGroup from "../../sections/SliderGroup/SliderGroup"
import DiagonalSlider from "../../sections/DiagonalSlider/DiagonalSlider"
import Paragraph from "../../sections/Paragraph/Paragraph"
import TwoColumnImageFirst from "../../sections/TwoColumn/TwoColumnImageFirst"
import TwoColumnTextFirst from "../../sections/TwoColumn/TwoColumnTextFirst"
import TwoColumnTextOnly from "../../sections/TwoColumn/TwoColumnTextOnly"
import ImageGroup from "../../sections/ImageGroup/ImageGroup"
import Tabs from "../../sections/Tabs/Tabs"
import ImageGrid from "../../sections/ImageGrid/ImageGrid"
import ModelOverviewLayout from "../ModelOverviewLayout/ModelOverviewLayout"
import VoucherFormLayout from "../VoucherFormLayout/VoucherFormLayout"
import BookingFormLayout from "../BookingFormLayout/BookingFormLayout"
import ContactFormLayout from "../ContactFormLayout/ContactFormLayout"
import CtaGroup from "../../sections/CtaGroup/CtaGroup"
import VehicleSpecsCounter from "../../sections/VehicleSpecsCounter/VehicleSpecsCounter"

export enum ContentTypes {
  ComponentLayoutCourseInformation = "StrapiGQLSource_ComponentLayoutCourseInformation",
  ComponentLayoutVehicleOverview = "StrapiGQLSource_ComponentLayoutVehicleOverview",
  ComponentLayoutVoucherForm = "StrapiGQLSource_ComponentLayoutVoucherForm",
  ComponentLayoutBookingForm = "StrapiGQLSource_ComponentLayoutBookingForm",
  ComponentLayoutContactForm = "StrapiGQLSource_ComponentLayoutContactForm",
  ComponentLayoutLinkBlockGroup = "StrapiGQLSource_ComponentLayoutLinkBlockGroup",
  ComponentLayoutAvailableCourseListing = "StrapiGQLSource_ComponentLayoutAvailableCourseListing",
  ComponentLayoutSliderGroup = "StrapiGQLSource_ComponentLayoutSliderGroup",
  ComponentLayoutDiagonalSlider = "StrapiGQLSource_ComponentLayoutDiagonalSlider",
  ComponentLayoutParagraph = "StrapiGQLSource_ComponentLayoutParagraph",
  ComponentLayoutTwoColumnImageFirst = "StrapiGQLSource_ComponentLayoutTwoColumnImageFirst",
  ComponentLayoutTwoColumnTextFirst = "StrapiGQLSource_ComponentLayoutTwoColumnTextFirst",
  ComponentLayoutTwoColumnTextOnly = "StrapiGQLSource_ComponentLayoutTwoColumnTextOnly",
  ComponentLayoutImageGroup = "StrapiGQLSource_ComponentLayoutImageGroup",
  ComponentLayoutTabs = "StrapiGQLSource_ComponentLayoutTabs",
  ComponentLayoutImageGrid = "StrapiGQLSource_ComponentLayoutImageGrid",
  ComponentLayoutModelOverview = "StrapiGQLSource_ComponentLayoutModelOverview",
  ComponentLayoutCtaGroup = "StrapiGQLSource_ComponentLayoutCtaGroup",
  ComponentLayoutVehicleSpecificationsCounter = "StrapiGQLSource_ComponentLayoutVehicleSpecificationsCounter",
}

export enum ContentSubtypes {
  ComponentLayoutLinkBlockGroup = "StrapiGQLSource_ComponentLayoutLinkBlockGroup",
  CourseModel = "StrapiGQLSource_CourseModel",
}

const resolveContentType = (
  sectionData: StrapiComponent
): JSX.Element | null => {
  switch (sectionData?.__typename) {
    case ContentTypes.ComponentLayoutCourseInformation:
      return (
        <CourseInformation
          key={sectionData.id}
          {...(sectionData as ComponentLayoutCourseInformation)}
        />
      )

    case ContentTypes.ComponentLayoutVehicleOverview:
      return (
        <VehicleOverview
          key={sectionData.id}
          {...(sectionData as ComponentLayoutVehicleOverview)}
        />
      )

    case ContentTypes.ComponentLayoutVoucherForm:
      return (
        <VoucherFormLayout
          key={sectionData.id}
          {...(sectionData as ComponentLayoutVoucherForm)}
        />
      )

    case ContentTypes.ComponentLayoutBookingForm:
      return (
        <BookingFormLayout
          key={sectionData.id}
          {...(sectionData as ComponentLayoutBookingForm)}
        />
      )

    case ContentTypes.ComponentLayoutContactForm:
      return (
        <ContactFormLayout
          key={sectionData.id}
          {...(sectionData as ComponentLayoutContactForm)}
        />
      )

    case ContentTypes.ComponentLayoutLinkBlockGroup:
      return (
        <LinkBlockGroup
          key={sectionData.id}
          {...(sectionData as ComponentLayoutLinkBlockGroup)}
        />
      )

    case ContentTypes.ComponentLayoutAvailableCourseListing:
      return <CourseListingLayout key={sectionData.id} />

    case ContentTypes.ComponentLayoutSliderGroup:
      return (
        <SliderGroup
          key={sectionData.id}
          {...(sectionData as ComponentLayoutSliderGroup)}
        />
      )

    case ContentTypes.ComponentLayoutDiagonalSlider:
      return (
        <DiagonalSlider
          key={sectionData.id}
          {...(sectionData as ComponentLayoutDiagonalSlider)}
        />
      )

    case ContentTypes.ComponentLayoutParagraph:
      return (
        <Paragraph
          key={sectionData.id}
          {...(sectionData as ComponentLayoutParagraph)}
        />
      )

    case ContentTypes.ComponentLayoutTwoColumnImageFirst:
      return (
        <TwoColumnImageFirst
          key={sectionData.id}
          {...(sectionData as ComponentLayoutTwoColumnImageFirst)}
        />
      )

    case ContentTypes.ComponentLayoutTwoColumnTextFirst:
      return (
        <TwoColumnTextFirst
          key={sectionData.id}
          {...(sectionData as ComponentLayoutTwoColumnTextFirst)}
        />
      )

    case ContentTypes.ComponentLayoutTwoColumnTextOnly:
      return (
        <TwoColumnTextOnly
          key={sectionData.id}
          {...(sectionData as ComponentLayoutTwoColumnTextOnly)}
        />
      )

    case ContentTypes.ComponentLayoutImageGroup:
      return (
        <ImageGroup
          key={sectionData.id}
          {...(sectionData as ComponentLayoutImageGroup)}
        />
      )

    case ContentTypes.ComponentLayoutTabs:
      return (
        <Tabs key={sectionData.id} {...(sectionData as ComponentLayoutTabs)} />
      )

    case ContentTypes.ComponentLayoutImageGrid:
      return (
        <ImageGrid
          key={sectionData.id}
          {...(sectionData as ComponentLayoutImageGrid)}
        />
      )

    case ContentTypes.ComponentLayoutModelOverview:
      return <ModelOverviewLayout key={sectionData.id} />

    case ContentTypes.ComponentLayoutCtaGroup:
      return (
        <CtaGroup
          key={sectionData.id}
          {...(sectionData as ComponentLayoutCtaGroup)}
        />
      )
    case ContentTypes.ComponentLayoutVehicleSpecificationsCounter:
      return (
        <VehicleSpecsCounter
          key={sectionData.id}
          {...(sectionData as ComponentLayoutVehicleSpecificationsCounter)}
        />
      )

    default:
      return null
  }
}

export default resolveContentType
