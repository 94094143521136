import React, { FunctionComponent } from "react"
import "./VehicleSpecsCounter.css"

const EngineIcon: FunctionComponent = ({}) => {
  return (
    // <svg
    //   className="text-wb-color-amg w-m"
    //   fill="currentColor"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 83.8 63.2"
    // >
    //   <path d="M59.1 63.2H31.7l-9.3-12.5h-8.7V40.3H7.3v10.2H0V22.2h7.3v10.9h6.4v-11h8.9l7.6-8.8H42v-6H30.8V0h29.9v7.3H49.3v5.9h12.1l10.7 12.4h11.7v31.6H63.5z" />
    // </svg>
    <div className="border border-solid border-wb-color-amg rounded-full p-xxs">
      <svg
        className="text-wb-color-amg w-m custom-icon"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill-rule="evenodd">
          <path d="M.078 20.654v21.028h2.109V20.654z"></path>
          <path d="M.61 30.112v2.112h6.87v-2.112H.61z"></path>
          <path d="M19.71 15.39a2.12 2.12 0 00-1.812 1.024l-2.546 4.24H8.543c-1.156 0-2.124.947-2.124 2.097v18.931c0 1.15.968 2.112 2.124 2.112h5.92c.72 0 1.42.236 1.983.684l2.967 2.376a5.325 5.325 0 003.31 1.149h20.725a2.127 2.127 0 002.109-2.112v-6.306h3.42l.843 3.402c.114.474.54.808 1.03.807h5.295c1.74 0 3.17-1.422 3.17-3.152V25.903c0-1.73-1.43-3.152-3.17-3.152h-5.294a1.057 1.057 0 00-1.03.792l-.844 3.416h-3.42v-4.208c0-1.15-.953-2.097-2.109-2.097h-5.747l-2.561-4.24a2.121 2.121 0 00-1.812-1.025H29.69v2.097h3.64l2.873 4.752c.19.317.534.512.906.513h6.34v5.265a1.052 1.052 0 001.062 1.056h5.279c.49 0 .917-.334 1.03-.808l.86-3.401h4.466c.597 0 1.062.447 1.062 1.04v14.739c0 .593-.465 1.04-1.062 1.04H51.68l-.86-3.4a1.057 1.057 0 00-1.03-.808h-5.28a1.061 1.061 0 00-.752.308c-.2.198-.31.467-.31.748v7.361H22.724a3.18 3.18 0 01-1.984-.683l-2.983-2.36a5.296 5.296 0 00-3.295-1.166H8.543V22.751h7.403c.371-.001.715-.196.906-.513l2.858-4.753h3.639V15.39h-3.64z"></path>
          <path d="M23.349 18.542v2.112h6.34v-2.112zm-5.295-7.362v2.112h16.93V11.18z"></path>
          <path d="M25.473 11.708v8.418h2.108v-8.418z"></path>
        </g>
      </svg>
    </div>
  )
}

export default EngineIcon
