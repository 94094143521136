import React, { FunctionComponent } from "react"
import ContactForm from "../../sections/ContactForm/ContactForm"
import {
  Legal,
  ComponentLayoutContactForm,
} from "../../../types/generated/strapi"
import { useStaticQuery, graphql } from "gatsby"

interface ContactFormData {
  site: {
    siteMetadata: {
      formApiBaseUrl?: string
    }
  }
  strapiGqlSource: {
    legal?: Partial<Legal>
  }
}

const ContactFormLayout: FunctionComponent<ComponentLayoutContactForm> = () => {
  const data: ContactFormData = useStaticQuery(graphql`
    query ContactFormQuery {
      site {
        siteMetadata {
          formApiBaseUrl
        }
      }
      strapiGqlSource {
        legal {
          MarketingConsent
        }
      }
    }
  `)

  return (
    <ContactForm
      marketingConsentText={data?.strapiGqlSource?.legal?.MarketingConsent}
      formApiBaseUrl={data?.site?.siteMetadata?.formApiBaseUrl}
    />
  )
}

export default ContactFormLayout

export const ContactFormLayoutFragment = graphql`
  fragment ContactFormLayoutFragment on StrapiGQLSource_ComponentLayoutContactForm {
    __typename
    _id
    id
    AnchorId
  }
`
