import React, { FunctionComponent, useState } from "react"
import { Course, CourseType, Maybe } from "../../../types/generated/strapi"
import { G, R, C } from "../../Workbench/Components"
import Title from "../../generic/Text/Title"
import { WBColorStyle } from "../../../types/Colours"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import LoadingState from "../../generic/Form/LoadingState"
import StepOne, { StepOneDetails } from "./StepOne"
import StepThree, { StepThreeDetails } from "./StepThree"
import StepTwo, { StepTwoDetails } from "./StepTwo"
import isFieldValid, { matchers } from "../../../utils/isFieldValid"
import {
  FormClient,
  Customer,
  Booking,
  Course as CourseData,
  ComponentEventSpectator,
} from "../../../types/generated/webApi"
import AnimatedEllipse from "../../generic/Form/AnimatedEllipse"
import ArrowLeft from "wb2-assets/dist/icons/arrow-left.svg"
import getPageMetadata from "../../../types/getPageMetadata"
import extractCustomerFromStepData from "../../../types/extractCustomerFromStepData"
import { extractSpectatorFromStepData } from "../../../types/extractBookingFromStepData"

enum Step {
  One = 1,
  Two = 2,
  Three = 3,
  Completed = 4,
}

export type BookingFormProps = {
  courseType?: CourseType
  courseTypes?: CourseType[]
  marketingConsentText?: string
  formApiBaseUrl?: string
}

const BookingForm: FunctionComponent<BookingFormProps> = ({
  courseType,
  courseTypes,
  marketingConsentText,
  formApiBaseUrl,
}) => {
  const activeCourseTypes = courseTypes?.filter(c => c.IsActive == true) || []

  const [step, setStep] = useState<Step>(Step.One)

  const [stepOneDetails, setStepOneDetails] = useState<StepOneDetails>({
    courseType: courseType,
  })
  const [stepTwoDetails, setStepTwoDetails] = useState<StepTwoDetails>({})
  const [stepThreeDetails, setStepThreeDetails] = useState<StepThreeDetails>({})

  const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Initial
  )

  const handleSubmit = async () => {
    const isFormValid =
      stepOneDetails.isValid &&
      stepTwoDetails.isValid &&
      stepThreeDetails.isValid

    setShouldShowErrors(true)
    if (isFormValid) {
      setLoadingState(LoadingState.IsLoading)
      const formClient = new FormClient(formApiBaseUrl)

      try {
        const submissionResult = await formClient.booking(
          new Booking({
            customer: extractCustomerFromStepData(
              stepTwoDetails,
              stepThreeDetails,
              marketingConsentText
            ),
            spectator: stepThreeDetails.spectatorName
              ? extractSpectatorFromStepData(stepThreeDetails)
              : undefined,
            course: CourseData.fromJS(stepOneDetails.course),
          })
        )
        if (submissionResult.success) {
          setLoadingState(LoadingState.Done)
        } else {
          setLoadingState(LoadingState.HasError)
        }
      } catch (error) {
        console.error(error)
        setLoadingState(LoadingState.HasError)
      }
    }
  }

  const wrapperClass = "mt-xl pb-m pt-s bg-wb-color-mineshaft"

  return loadingState === LoadingState.Done ? (
    <div className={wrapperClass}>
      <G>
        <R className="bg-wb-color-mineshaft">
          <C desktopSize={12} tabletSize={12} mobileSize={12}>
            <Title
              text="Form Submitted"
              subtext="Course Booking"
              textColor={WBColorStyle.White}
              useDefaultAnimation={true}
            />
            <p className=" mt-xs text-wb-color-white leading-7">
              Your form has been submitted. Someone from the AMG Driving Academy
              will be in touch soon.
            </p>
            <AnyLink
              useButton={true}
              buttonType="submit"
              linkStyle={LinkStyle.SecondaryButton}
              onButtonClick={() => {
                setLoadingState(LoadingState.Initial)
                setStep(Step.One)
              }}
              iconRight={false}
              iconSrc={ArrowLeft}
              className="mt-s"
            >
              Book Another Course
            </AnyLink>
          </C>
        </R>
      </G>
    </div>
  ) : (
    <form
      data-aos="fade"
      className={wrapperClass}
      noValidate={true}
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <G>
        <R>
          <C>
            <Title
              text={
                step === Step.One
                  ? "Book Course"
                  : step === Step.Two
                  ? "Driver Details"
                  : step === Step.Three
                  ? "Additonal information"
                  : "Book Submitted"
              }
              subtext={`Step ${step}/3`}
              textColor={WBColorStyle.White }
            />
          </C>
        </R>
      </G>

      {step === Step.One ? (
        <StepOne
          onValueChange={newDetails =>
            setStepOneDetails({ ...stepOneDetails, ...newDetails })
          }
          value={stepOneDetails}
          loadingState={loadingState}
          shouldShowErrors={shouldShowErrors}
          courseTypes={activeCourseTypes}
        />
      ) : step === Step.Two ? (
        <StepTwo
          onValueChange={newDetails =>
            setStepTwoDetails({ ...stepTwoDetails, ...newDetails })
          }
          value={stepTwoDetails}
          loadingState={loadingState}
          shouldShowErrors={shouldShowErrors}
        />
      ) : step === Step.Three ? (
        <StepThree
          onValueChange={newDetails =>
            setStepThreeDetails({ ...stepThreeDetails, ...newDetails })
          }
          value={stepThreeDetails}
          loadingState={loadingState}
          shouldShowErrors={shouldShowErrors}
          marketingConsentText={marketingConsentText}
        />
      ) : (
        "Booking Submitted"
      )}

      <G>
        <R className="mt-xs">
          <C>
            {step === Step.Two || step === Step.Three ? (
              <AnyLink
                useButton={true}
                buttonType="button"
                linkStyle={LinkStyle.SecondaryButton}
                onButtonClick={() => {
                  setStep(step === Step.Two ? Step.One : Step.Two)
                }}
                isButtonDisabled={loadingState === LoadingState.IsLoading}
                iconSrc={ArrowLeft}
              >
                Previous
              </AnyLink>
            ) : null}

            {step === Step.One || step === Step.Two ? (
              <AnyLink
                useButton={true}
                buttonType="submit"
                linkStyle={LinkStyle.PrimaryButton}
                onButtonClick={() => {
                  if (
                    (step === Step.One && stepOneDetails.isValid) ||
                    (step === Step.Two && stepTwoDetails.isValid)
                  ) {
                    setStep(step === Step.One ? Step.Two : Step.Three)
                    // comment out if you want to show error after first error
                    setShouldShowErrors(false)
                  } else {
                    setShouldShowErrors(true)
                  }
                }}
                isButtonDisabled={loadingState === LoadingState.IsLoading}
                iconRight={true}
              >
                Next
              </AnyLink>
            ) : null}

            {step === Step.Three ? (
              <AnyLink
                useButton={true}
                buttonType="submit"
                linkStyle={LinkStyle.PrimaryButton}
                onButtonClick={() => {
                  handleSubmit()
                }}
                isButtonDisabled={loadingState === LoadingState.IsLoading}
                iconRight={true}
              >
                {loadingState === LoadingState.IsLoading ? (
                  <span>
                    Loading <AnimatedEllipse />
                  </span>
                ) : (
                  "Book Now"
                )}
              </AnyLink>
            ) : null}
          </C>
        </R>
      </G>
    </form>
  )
}

export default BookingForm
