import React, { FunctionComponent } from "react"

const SettingsIcon: FunctionComponent = ({}) => {
  return (
    // <svg
    //   className="text-wb-color-amg w-m"
    //   fill="currentColor"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 65.6 65.6"
    // >
    //   <path d="M44.557 53.134l-5.681 2.339-1.782 10.137h-8.577l-1.782-10.137-5.681-2.339-8.466 6.015-6.015-6.127 5.904-8.466-2.339-5.681L0 37.205v-8.689l10.137-1.782 2.339-5.681-5.904-8.354 6.015-6.127 8.466 5.904 5.681-2.339L28.516 0h8.577l1.782 10.137 5.681 2.339 8.354-5.904 6.127 6.127-5.904 8.354 2.339 5.681 10.137 1.782v8.577l-10.137 1.782-2.339 5.681 5.904 8.466-6.127 6.015-8.353-5.903zm1.337-19.939c0-3.453-1.225-6.349-3.787-8.8-2.451-2.562-5.347-3.787-8.8-3.787-3.453 0-6.349 1.225-8.911 3.787-2.451 2.451-3.676 5.347-3.676 8.8s1.225 6.349 3.676 8.911c2.562 2.451 5.458 3.676 8.911 3.676 3.453 0 6.349-1.225 8.8-3.676 2.561-2.562 3.787-5.458 3.787-8.911z" />
    // </svg>
    <div className="border border-solid border-wb-color-amg rounded-full p-xxs">
      <svg
        className="text-wb-color-amg w-m"
        viewBox="0 0 60 60"
        id="amg-e-icon-performance-gear"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          stroke="#B21E23"
          stroke-width="2"
          d="M38.24 44.253l-3.983 1.64L33.007 53h-6.013l-1.25-7.107-3.983-1.64-5.935 4.217-4.218-4.296 4.14-5.935-1.64-3.983L7 33.085v-6.092l7.107-1.25 1.64-3.982-4.14-5.858 4.218-4.295 5.936 4.14 3.983-1.64L26.994 7h6.013l1.25 7.107 3.982 1.64 5.857-4.14 4.296 4.296-4.14 5.858 1.64 3.983L53 26.993v6.013l-7.107 1.25-1.64 3.983 4.14 5.935-4.297 4.218-5.856-4.14zm.937-13.98c0-2.42-.859-4.45-2.655-6.17-1.718-1.796-3.749-2.654-6.17-2.654-2.42 0-4.451.858-6.247 2.655-1.719 1.718-2.578 3.748-2.578 6.17 0 2.42.86 4.45 2.578 6.247 1.796 1.718 3.826 2.577 6.247 2.577s4.452-.859 6.17-2.577c1.796-1.796 2.655-3.827 2.655-6.248z"
        ></path>
      </svg>
    </div>
  )
}

export default SettingsIcon
