import { getDateMeta, toDateSafely } from "../../../utils/date"
import { Course, CourseType } from "../../../types/generated/strapi"
import { CourseByMonthYear } from "./StepOne"

export const getOrdinalNum = (number: number) => {
  let selector

  if (number <= 0) {
    selector = 4
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0
  } else {
    selector = number % 10
  }

  return number + ["th", "st", "nd", "rd", ""][selector]
}

/**
 * Converts Given Course Date To `LongMonth Year` Format
 * E.g: "September 2020"
 * @param date
 */

export const convertToMonthYear = (
  date: Date | string | undefined | null
): string | undefined => {
  const checkedDate: Date | undefined =
    date instanceof Date
      ? date
      : typeof toDateSafely(date) !== "undefined"
      ? toDateSafely(date)
      : undefined
  return checkedDate
    ? `${getDateMeta(checkedDate).monthLong} ${getDateMeta(checkedDate).year}`
    : ""
}

/**
 * Transforms Courses By Course Type Data To Courses By Date,
 * And Filters Out Courses Before The Current Date
 * @param courses
 */
export const filterCoursesByMonthAndYear = (
  courses: (Course | undefined)[] | undefined
): CourseByMonthYear[] | undefined => {
  let sortedCourseListing = courses?.sort((a, b) => {
    const c = a?.Date instanceof Date ? a.Date : toDateSafely(a?.Date)
    const d = b?.Date instanceof Date ? b.Date : toDateSafely(b?.Date)

    return c && d ? c?.getTime() - d?.getTime() : 0
  })

  let coursesByDate: CourseByMonthYear[] | undefined = sortedCourseListing?.map(
    course => {
      return {
        monthYear: convertToMonthYear(course?.Date),
      }
    }
  )
  coursesByDate?.forEach(courseByDate => {
    const matchingCourse: Course[] = []
    courses?.forEach(course => {
      if (
        course &&
        courseByDate.monthYear === convertToMonthYear(course?.Date)
      ) {
        if (new Date(course.Date) > new Date()) {
          matchingCourse.push(course)
        }
      }
    })
    courseByDate.courses = matchingCourse
  })

  // filter duplicate courses by date

  coursesByDate = coursesByDate?.filter(
    (courseByDate, index, self) =>
      index === self.findIndex(t => t.monthYear === courseByDate.monthYear) &&
      courseByDate?.courses?.length !== 0
  )

  return coursesByDate
}


export const sortedCourseTypesByLevel = (courseTypesToSort: CourseType[] | undefined) =>
{
 return courseTypesToSort?.sort(
    (a: CourseType, b: CourseType) => {

      if (a.Name ===  undefined || b.Name === undefined)
      return 0

      let  l1 = parseInt(a?.Name.match(/\d+/g)?.join() ?? "-1")
      let  l2 = parseInt(b?.Name.match(/\d+/g)?.join() ?? "-1")

      if (l1 ===  undefined || l1 === -1 || l2 === undefined || l2 === -1)
      return 0

      else
      return (l1 - l2) || a.Name.localeCompare(b.Name)
    }
   )
}