import { FunctionComponent } from "react"
import {
  CourseType as CourseTypeModel,
  Vehicle,
} from "../../../types/generated/strapi"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import ModelOverview from "../../sections/ModelOverview/ModelOverview"

type ModelOverviewLayoutData = {
  strapiGqlSource: {
    vehicles?: Vehicle[]
  }
}

const ModelOverviewLayout: FunctionComponent = () => {
  const data: ModelOverviewLayoutData = useStaticQuery(graphql`
    query {
      strapiGqlSource {
        vehicles {
          ...VehicleFragment
        }
      }
    }
  `)

  return <ModelOverview data={data?.strapiGqlSource?.vehicles} />
}

export default ModelOverviewLayout

export const modelOverviewFragment = graphql`
  fragment ModelOverviewFragment on StrapiGQLSource_ComponentLayoutModelOverview {
    __typename
    _id
    id
    AnchorId
  }
`
