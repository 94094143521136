import { StepThreeDetails } from "../components/sections/BookingForm/StepThree"
import { ComponentEventSpectator } from "./generated/webApi"

/**
 * Convert Extract Spectator from Booking Form Component
 * @param stepThreeDetails
 */

export const extractSpectatorFromStepData = (
  stepThreeDetails: StepThreeDetails
): ComponentEventSpectator =>
  new ComponentEventSpectator({
    name: stepThreeDetails.spectatorName,
    dietary: stepThreeDetails.spectatorDietary,
  })

export default extractSpectatorFromStepData
