import React, { FunctionComponent, useState, useEffect } from "react"

type specValueCounterProps = {
  SpecValue?: string
  Duration?: number
}
const specValueCounter: FunctionComponent<specValueCounterProps> = ({
  SpecValue,
  Duration = 1500,
}) => {
  const [specValue, setSpecValue] = useState<number>(0.0)
  const [specValueTimer, setSpecValueTimer] = useState<NodeJS.Timeout>()

  const isInt = (num: number) => {
    return Number.isInteger(num)
  }
  useEffect(() => {
    const specCounterinterval = setInterval(() => {
      if (isInt(parseFloat(SpecValue ? SpecValue : ""))) {
        setSpecValue(specValue =>
          specValue < parseInt(SpecValue ? SpecValue : "")
            ? specValue + 1
            : specValue
        )
      } else {
        setSpecValue(specValue =>
          parseFloat(specValue.toFixed(1)) <
          parseFloat(SpecValue ? SpecValue : "")
            ? specValue + 0.1
            : specValue
        )
      }
      setSpecValueTimer(specCounterinterval)
    }, (1 / parseInt(SpecValue ? SpecValue : "")) * (1 / (SpecValue ? SpecValue?.length : 1)) * Duration)

    return () => {
      clearTimeout(specCounterinterval)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (specValue >= parseFloat(SpecValue ? SpecValue : "")) {
        clearInterval(specValueTimer ? specValueTimer : setTimeout(() => {}, 0))
      }
    }, 0.1)
  })
  return <>{isInt(specValue) ? specValue : specValue.toFixed(1)}</>
}

export default specValueCounter
