import { useEffect, useRef, useState } from "react"

export const useIntersect = <T extends Element>(
  options: IntersectionObserverInit
): [React.RefObject<T>, IntersectionObserverEntry | undefined] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>()
  const node = useRef<T>(null)

  let observer = useRef<IntersectionObserver>()

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => updateEntry(entry),
      options
    )

    const { current: currentObserver } = observer

    currentObserver.disconnect()

    if (node.current) currentObserver.observe(node.current)

    return () => currentObserver.disconnect()
  }, [node])

  return [node, entry]
}
