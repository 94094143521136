import { Customer } from "./generated/webApi"
import { StepThreeDetails } from "../components/sections/BookingForm/StepThree"
import { StepTwoDetails } from "../components/sections/BookingForm/StepTwo"
import { convertTitleShipToTitle } from "./convertPersonalDetailsToCustomer"

/**
 * Convert PersonalDetailsForm to DLP Customer
 * @param personalDetail
 * @param marketingConsent
 * @param marketingConsentText
 */

const extractCustomerFromStepData = (
  stepTwoDetails: StepTwoDetails,
  stepThreeDetails: StepThreeDetails,
  marketingConsentText?: string
): Customer => {
  return new Customer({
    title: convertTitleShipToTitle(stepTwoDetails.title),
    firstName: stepTwoDetails.firstName,
    lastName: stepTwoDetails.lastName,
    email: stepTwoDetails.email,
    phone: stepTwoDetails.phone,
    postalCode: stepThreeDetails.postalCode,
    marketingConsent: stepThreeDetails.marketingConsent,
    marketingConsentText: marketingConsentText,
  })
}

export default extractCustomerFromStepData
