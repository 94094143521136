import {
  ComponentContentSlider,
  ENUM_COMPONENTCONTENTSLIDER_POSITION as PositionType,
  Course,
  ComponentContentTitle,
  ENUM_COMPONENTCONTENTTITLE_HEADINGSIZE as hSize,
} from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import Title from "../../generic/Text/Title"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import { G, R, C } from "../../Workbench/Components"
import { toFullDateText, toDateSafely, toPrettyDate } from "../../../utils/date"
import DoubleCTA from "../../generic/AnyLink/DoubleCTA"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import { getNextCourse, getNextCourseDate } from "../CourseListing/CourseCard"
import TitleLayout, { TitleLayoutProps } from "../../generic/Text/TitleLayout"
import { WBColorStyle } from "../../../types/Colours"
import formatPrice from "../../../types/formatPrice"
import { WBFontStyle } from "../../../types/Fonts"

const Slide: FunctionComponent<Partial<ComponentContentSlider>> = ({
  Detail,
  EventDate,
  Title: title,
  Image,
  CTAPrimary,
  CTASecondary,
  Position,
  CourseType,
}) => {
  const animationType: string = "fade"

  const desktopSizeAdjustment: number = CourseType ? 6 : 5;
  const desktopSizeAdjustmentRightPos: number = CourseType ? 6 : 7;
  const nextCourse = getNextCourse(CourseType?.Courses as Course[] | undefined)

  const nextCourseSpacesLeft: number | undefined =
    nextCourse?.TotalSpaces && nextCourse.TotalSpaces > 0
      ? nextCourse?.TotalSpaces -
        (nextCourse?.Bookings ? nextCourse.Bookings.length : 0)
      : undefined

  const nextCourseDate = getNextCourseDate(
    CourseType?.Courses as Course[] | undefined
  )

  return (
    <div className="relative bg-wb-color-black">
      <div
        data-aos="fade"
        data-aos-duration="1500"
        className="relative bg-wb-color-black"
      >
        <StrapiImage
          shouldAutoPlay={true}
          shouldBeMuted={true}
          shouldLoop={true}
          shouldShowControls={false}
          className="w-full h-full object-cover md:relative md:h-screen-w/o-header opacity-100"
          isEagerLoaded={true}
          {...Image}
        />
      </div>

      <div className="md:absolute left-0 top-m w-screen max-w-full">
        <G className="relative">
          <R>
            {/* {nextCourseSpacesLeft ? (
              <div className="md:hidden absolute right-0 -top-xs text-center">
                <div className="absolute w-1/2 h-xs bg-wb-color-silver -right-3xs top-0 transform skew-x-4/25"></div>
                <div className="relative px-xxs py-xxs bg-wb-color-white">
                  <h2 className="relative wb2-type-heading-m text-wb-color-black -mb-xxs">
                    {nextCourseSpacesLeft}
                  </h2>
                  <p className="wb2-type-copy text-wb-color-black">Spots</p>
                  <p className="hidden md:block -mt-3xs">Left</p>
                </div>
              </div>
            ) : null} */}
            {Position == PositionType.Right ? (
              <C desktopSize={desktopSizeAdjustmentRightPos} tabletSize={4} className="hidden md:block">
                <br />
              </C>
            ) : null}
            <C desktopSize={desktopSizeAdjustment} tabletSize={12} mobileSize={12}>

              {CourseType ? (
                <div className="relative">
                  <div className="flex flex-row m-auto">
                    <div className="mr-3xs">
                      {CourseType?.CourseLogoMainSlider ? (
                        <StrapiImage
                          shouldAutoPlay={true}
                          shouldShowControls={false}
                          shouldBeMuted={true}
                          shouldLoop={true}
                          {...CourseType?.CourseLogoMainSlider}
                          className="min-w-1/3 max-w-95 md:max-w-680"
                        />
                      ) : null}
                    </div>
                    <div>
                      <div className="flex flex-col">
                        <span className="inline-block text-wb-color-black">
                          <span className="inline-block transform px-xxs -skew-x-1/13 bg-wb-color-amg">
                            <span className="inline-block pl-3xs py-3xs transform uppercase">
                              <Title
                                text={CourseType?.Name}
                                textColor={WBColorStyle.White}
                                hasOverline={false}
                                headingSize={hSize.HeadingXL}
                                textStyle={WBFontStyle.HeadingS}
                              />
                            </span>
                          </span>
                        </span>
                      </div>
                      <div className="mt-2">
                        {CourseType?.Price ? (
                          <div className="text-wb-color-white text-m font-demi font-sans wb2-type-heading-m italic">
                            R {formatPrice(CourseType?.Price)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {typeof nextCourseSpacesLeft !== "undefined" &&
                    nextCourseSpacesLeft > 0 ? (
                      <div className="flex transform -skew-x-1/13">
                        <span className="inline-block bg-wb-color-white pt-3xs pb-3xs px-3xs ">
                          <span className="relative wb2-type-heading-s font-sans text-wb-color-black">
                            <span className="block text-center">{nextCourseSpacesLeft}</span>
                          <div className="wb2-type-hint flex flex-col"><span>Spots</span><span>Left</span></div>
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <DoubleCTA primary={CTAPrimary} secondary={CTASecondary} />
                  </div>
                </div>
              ) :                 <div
              data-aos={animationType}
              className="relative md:px-xs py-xs md:bg-wb-color-black md:bg-opacity-75"
            >
              {title ? (
                <TitleLayout
                  {...(title as ComponentContentTitle)}
                  Text={title?.Text}
                  textColor={WBColorStyle.White}
                />
              ) : null}
              {/* {typeof nextCourseSpacesLeft !== "undefined" &&
            nextCourseSpacesLeft > 0 ? (
              <div className="absolute right-xs -top-xxs">
                <div className="relative px-xxs py-xxs text-center  bg-wb-color-white">
                  <h2 className="relative wb2-type-heading-l font-sans text-wb-color-black -mb-xxs">
                    {nextCourseSpacesLeft}
                  </h2>
                  <p className="wb2-type-copy text-wb-color-black">Spots</p>
                  <p className="hidden md:block -mt-3xs">Left</p>
                </div>
              </div>
            ) : null} */}
              {/* {EventDate || nextCourseDate ? (
              <div className="mt-xxs text-wb-color-white font-demi">
                {toFullDateText(EventDate) ||
                  (nextCourseDate ? `Next Event: ${nextCourseDate}` : "")}
              </div>
            ) : null} */}
              {Detail ? (
                <div className="mt-xs">
                  <ParagraphGroup
                    text={Detail}
                    className="text-wb-color-white leading-5"
                    blockLevel="mt-xxs"
                  />
                </div>
              ) : null}
              <div>
                <DoubleCTA primary={CTAPrimary} secondary={CTASecondary} />
              </div>
            </div>}
            </C>
          </R>
        </G>
      </div>
    </div>
  )
}

export default Slide

export const SlideFragment = graphql`
  fragment SlideFragment on StrapiGQLSource_ComponentContentSlider {
    id
    _id
    Detail
    EventDate
    Position
    CourseType {
      id
      ShortDescription
      Duration
      Slug
      Name
      Price
      CourseLogoMainSlider {
        ...ImageComponentFragment
      }
      Courses {
        id
        Date
        TotalSpaces
        Bookings {
          id
        }
      }
    }
    Title {
      ...TitleComponentFragment
    }
    Image {
      ...ImageComponentFragment
    }
    CTAPrimary {
      ...NavigationLinkFragment
    }
    CTASecondary {
      ...NavigationLinkFragment
    }
  }
`
