import { FunctionComponent } from "react"
import React from "react"
import { Titleship } from "../../../types/generated/webApi"
import { G, R, C } from "../../Workbench/Components"
import RadioGroup from "./RadioGroup"
import InputBox from "./InputBox"
import { ParagraphGroup } from "../../sections/Paragraph/ParagraphGroup"
import LoadingState from "./LoadingState"
import isFieldValid, { matchers } from "../../../utils/isFieldValid"

export interface PersonalDetailProps {
  value: PersonalDetails
  optionalFields?: OptionalFields
  shouldShowErrors?: boolean
  onValueChange: (newValue?: PersonalDetails) => void
  loadingState?: LoadingState
}

export interface PersonalDetails {
  title?: Titleship
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  postalCode?: string
}

export interface OptionalFields {
  title?: boolean
  firstName?: boolean
  lastName?: boolean
  email?: boolean
  phone?: boolean
  postalCode?: boolean
}

const PersonalDetailFields: FunctionComponent<PersonalDetailProps> = ({
  value,
  optionalFields = {},
  shouldShowErrors,
  onValueChange,
  loadingState = LoadingState.Initial,
}) => {
  const isDisabled = loadingState === LoadingState.IsLoading

  const showFieldError = (
    match: RegExp,
    value?: any,
    minLength?: number,
    maxLength?: number
  ) => {
    return (
      shouldShowErrors === true &&
      !isFieldValid(match, value, minLength, maxLength)
    )
  }

  const handleFieldChange = (newValue?: PersonalDetails) => {
    const validations = [
      optionalFields.title || isFieldValid(matchers.any, newValue?.title),
      optionalFields.firstName ||
        isFieldValid(matchers.any, newValue?.firstName),
      optionalFields.lastName ||
        isFieldValid(matchers.any, newValue?.lastName),
      optionalFields.email || isFieldValid(matchers.email, newValue?.email),
      optionalFields.phone ||
        isFieldValid(matchers.phone, newValue?.phone, 10, 14),
      optionalFields.postalCode ||
        isFieldValid(matchers.postalCode, newValue?.postalCode, 3, 5),
    ]

    const isValid =
      validations.filter(isFieldValid => isFieldValid !== true).length === 0

    onValueChange({ ...newValue, isValid } as PersonalDetails)
  }

  return (
    <>
      <G className="mt-xs">
        <R>
          <C desktopSize={12} tabletSize={12} mobileSize={12}>
            <label className="block text-wb-color-white text-sm font-demi">
              Title
              {!optionalFields.title ? (
                <span className="text-wb-color-amg">*</span>
              ) : null}
            </label>
            <RadioGroup
              onChange={(title: Titleship) => {
                // Pass title as Enum(number)
                handleFieldChange({ ...value, title })
              }}
              value={value.title}
              isHorizontal={true}
              options={[
                { name: "Mr", label: "Mr", value: Titleship.Mr },
                { name: "Mrs", label: "Mrs", value: Titleship.Mrs },
                { name: "Ms", label: "Ms", value: Titleship.Ms },
                { name: "Dr", label: "Dr", value: Titleship.Dr },
                {
                  name: "Prof",
                  label: "Prof",
                  value: Titleship.Prof,
                },
              ]}
              error="Please select an option"
              hasError={showFieldError(matchers.any, value.title)}
              isDisabled={isDisabled}
            />
          </C>
        </R>
      </G>
      <G>
        <R>
          <C
            className="mt-xs"
            desktopSize={4}
            tabletSize={5}
            smallTabletSize={12}
            mobileSize={12}
          >
            <InputBox
              label="First Name"
              name="FirstName"
              type="text"
              value={value.firstName}
              onChange={(firstName?: string) =>
                handleFieldChange({ ...value, firstName })
              }
              isRequired={!optionalFields.firstName}
              error="Please enter First Name"
              hasError={showFieldError(matchers.any, value.firstName)}
              isDisabled={isDisabled}
            />
          </C>
          <C
            className="mt-xs"
            desktopSize={4}
            tabletSize={5}
            smallTabletSize={12}
            mobileSize={12}
          >
            <InputBox
              label="Last Name"
              name="LastName"
              type="text"
              value={value.lastName}
              onChange={(lastName?: string) =>
                handleFieldChange({ ...value, lastName })
              }
              isRequired={!optionalFields.lastName}
              error="Please enter Last Name"
              hasError={showFieldError(matchers.any, value.lastName)}
              isDisabled={isDisabled}
            />
          </C>
        </R>
      </G>
      <G>
        <R>
          <C
            className="mt-xs"
            desktopSize={3}
            tabletSize={4}
            smallTabletSize={12}
            mobileSize={12}
          >
            <InputBox
              label="Email"
              name="Email"
              type="email"
              value={value.email}
              onChange={(email?: string) =>
                handleFieldChange({ ...value, email })
              }
              isRequired={!optionalFields.email}
              error="Please enter Email"
              hasError={showFieldError(matchers.email, value.email)}
              isDisabled={isDisabled}
            />
          </C>
          <C
            className="mt-xs"
            desktopSize={3}
            tabletSize={4}
            smallTabletSize={12}
            mobileSize={12}
          >
            <InputBox
              label="Contact Number"
              name="Tel"
              type="tel"
              value={value.phone}
              onChange={(phone?: string) =>
                handleFieldChange({ ...value, phone })
              }
              isRequired={!optionalFields.phone}
              error="Please enter Contact Number"
              hasError={showFieldError(matchers.phone, value.phone, 8, 13)}
              isDisabled={isDisabled}
            />
          </C>
          <C
            className="mt-xs"
            desktopSize={2}
            tabletSize={2}
            smallTabletSize={12}
            mobileSize={12}
          >
            <InputBox
              label="Postal Code"
              name="PostalCode"
              type="text"
              value={value.postalCode}
              onChange={(postalCode?: string) =>
                handleFieldChange({ ...value, postalCode })
              }
              isRequired={!optionalFields.postalCode}
              error="Please enter Postal Code"
              hasError={showFieldError(
                matchers.postalCode,
                value.postalCode,
                3,
                5
              )}
              isDisabled={isDisabled}
            />
          </C>
        </R>
      </G>
    </>
  )
}

export default PersonalDetailFields
