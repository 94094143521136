import React, { FunctionComponent } from "react"

const MileageIcon: FunctionComponent = ({}) => {
  return (
    // <svg
    //   fill="currentColor"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 69.408 48.238"
    //   className="text-wb-color-amg w-m"
    // >
    //   <path d="M40.106 33.534v-.78c0-.446-.111-.78-.223-1.114l14.481-15.372-3.119-3.119L35.762 27.63c-.557-.334-1.671-.223-1.782-.223-3.453 0-6.349 2.785-6.349 6.238a6.228 6.228 0 0 0 6.238 6.238c1.671 0 3.23-.668 4.456-1.894 1.224-1.224 1.781-2.673 1.781-4.455zm29.297 1.226c0 4.344-.891 8.911-2.673 13.478H2.79C.896 44.005.005 39.549.005 34.76-.329 16.045 15.934-.329 34.76.005c18.713-.334 34.977 16.04 34.643 34.755z" />
    // </svg>
    <div className="border border-solid border-wb-color-amg rounded-full p-xxs">
      <svg
        fill="currentColor"
        viewBox="0 0 60 60"
        id="amg-e-icon-performance-performance"
        xmlns="http://www.w3.org/2000/svg"
        className="text-wb-color-amg w-m"
      >
        <path
          fill="#D9252B"
          d="M30 0C13.427 0 0 13.427 0 30s13.427 30 30 30 30-13.427 30-30S46.573 0 30 0zm-.91 1.791v5.46a.91.91 0 101.82 0v-5.46a28.04 28.04 0 0112.398 3.327l-2.73 4.72a.917.917 0 101.593.91l2.73-4.72a28.324 28.324 0 019.07 9.072l-4.72 2.73a.91.91 0 10.91 1.563l4.72-2.701A28.04 28.04 0 0158.21 29.09h-5.46a.914.914 0 100 1.82h5.46a28.069 28.069 0 01-3.299 12.398l-4.749-2.73a.91.91 0 00-.54-.142.91.91 0 00-.37 1.706L54 44.872a28.332 28.332 0 01-9.1 9.1l-2.73-4.75a.91.91 0 00-.938-.454.91.91 0 00-.654 1.365l2.758 4.749c-3.721 2-7.94 3.184-12.426 3.327v-5.46a.91.91 0 10-1.82 0v5.46C13.923 57.726 1.763 45.284 1.763 30c0-15.284 12.16-27.726 27.327-28.209zM15.014 14.275a.91.91 0 00-.455 1.564l11.005 11.005A5.407 5.407 0 0024.54 30c0 3.005 2.455 5.46 5.46 5.46s5.46-2.455 5.46-5.46-2.455-5.46-5.46-5.46a5.407 5.407 0 00-3.156 1.024L15.839 14.559a.91.91 0 00-.825-.284zM30 26.36A3.626 3.626 0 0133.64 30 3.626 3.626 0 0130 33.64 3.626 3.626 0 0126.36 30a3.647 3.647 0 011.052-2.588A3.643 3.643 0 0130 26.36z"
        ></path>
      </svg>
    </div>
  )
}

export default MileageIcon
