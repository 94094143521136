import "./VehicleOverview.css"
import React, { FunctionComponent, ReactNode } from "react"
import {
  ENUM_COURSETYPE_DURATION as DurationEnum,
  ComponentLayoutVehicleOverview,
} from "../../../types/generated/strapi"

import { G, R, C, P } from "../../Workbench/Components"
import Title from "../../generic/Text/Title"
import { WBColorBGStyle, WBColorStyle } from "../../../types/Colours"
import { WBFontStyle } from "../../../types/Fonts"
import Table, { HeadingPosition } from "../../generic/Table/Table"
import { graphql } from "gatsby"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"

const timeToString = (time?: string): string => {
  const matches = time?.match(/\d\d:\d\d/)

  return matches && matches?.length > 0 ? matches[0] : ""
}

const durationToString = (duration?: DurationEnum): string =>
  duration == DurationEnum.HalfDay ? "Half Day" : "Full Day"

const VehicleOverview: FunctionComponent<Partial<
  ComponentLayoutVehicleOverview
>> = ({ Title: TitleData, Vehicle, Overview }) => {
  let headers: string[] = []
  let agenda: string[][] = []

  if (Vehicle?.EngineDescription) {
    headers.push("Engine")
    agenda.push([Vehicle.EngineDescription])
  }

  if (Vehicle?.Power) {
    headers.push("Power")
    agenda.push([Vehicle.Power])
  }

  if (Vehicle?.Torque) {
    headers.push("Torque")
    agenda.push([Vehicle.Torque])
  }

  if (Vehicle?.TopSpeed) {
    headers.push("Top Speed")
    agenda.push([Vehicle.TopSpeed])
  }

  if (Vehicle?.Acceleration) {
    headers.push("0-100km/h")
    agenda.push([Vehicle.Acceleration])
  }

  if (Vehicle?.Gearbox) {
    headers.push("Gearbox")
    agenda.push([Vehicle.Gearbox])
  }

  return (
    <div
      className={`vehicle-overview ${WBColorBGStyle.Black} ${WBColorStyle.White} mt-xl`}
    >
      <G>
        <R>
          <C desktopSize={6}>
            <Title
              text={TitleData?.Text}
              subtext={TitleData?.Subtext}
              useDefaultAnimation={true}
            />

            <ParagraphGroup
              text={Overview}
              className="text-wb-color-white leading-7"
              blockLevel="mt-xs"
              useAnimation={true}
            />
          </C>
          <C desktopSize={6}>
            {headers && agenda ? (
              <Table
                className="vehicle-overview__specs"
                headingPosition={HeadingPosition.Left}
                headings={headers}
                rows={agenda}
                useAnimation={true}
              ></Table>
            ) : null}
          </C>
        </R>
      </G>
    </div>
  )
}
export default VehicleOverview

export const CourseInformationComponentFragment = graphql`
  fragment VehicleOverviewComponentFragment on StrapiGQLSource_ComponentLayoutVehicleOverview {
    id
    __typename
    Overview
    AnchorId
    Title {
      Subtext
      Text
      id
    }
    Vehicle {
      EngineDescription
      Engine
      Body
      Gearbox
      Name
      Power
      Price
      TopSpeed
      Torque
      id
    }
  }
`
