import { FunctionComponent } from "react"
import StrapiImage, { StrapiImageProps } from "../StrapiImage/StrapiImage"
import YouTube, { YouTubeProps } from "../Youtube/YouTube"
import React from "react"
export interface ImageOrYouTubeProps {
  youTube?: YouTubeProps
  image?: StrapiImageProps
}

/**
 * Displays a YouTube component if the URL exists, otherwise display the 
 * StrapiImage component
 */
const YouTubeOrImage: FunctionComponent<ImageOrYouTubeProps> = ({
  youTube,
  image,
}) => {

    console.log(youTube)


  return youTube?.url ? (
    <YouTube {...youTube} />
  ) : (
    <StrapiImage {...image} />
  )
}

export default YouTubeOrImage