/**
 * Safely push data into the dataLayer
 * @param {*} data dataLayer data
 */
export const pushEvent = data => {
  try {
    if (typeof window !== "undefined") {
      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(data)
    }
  } catch (e) {
    console.error("dataLayer push failed", data)
  }
}

let formStartFired = false

/**
 * Push a formStart event into the DataLayer
 * @param {string} formId
 * @param {string} formType
 */
export const formStart = (formId, formType = "contact") => {
  try {
    if (!formStartFired) {
      formStartFired = true
      pushEvent({
        event: "formStart",
        form: {
          id: formId,
          type: formType,
        },
      })
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * Push event for when form field is entered
 * @param {string } formId
 * @param {string} formType
 * @param {string} fieldName
 */
export const formFieldEntered = (
  formId,
  formType = "contact",
  fieldName = null
) => {
  try {
    pushEvent({
      event: "formFieldEntered",
      form: {
        id: formId,
        type: formType,
        field: [
          {
            id: fieldName,
          },
        ],
      },
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Push event for when a form field errors
 * @param {string} formId
 * @param {string} formType
 */
export const formFieldErrored = (formId, formType = "contact") => {
  try {
    pushEvent({
      event: "formFieldErrored",
      form: {
        id: formId,
        type: formType,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Push event when a form submission fails due to an error
 * @param {string} formId
 * @param {string} formType
 * @param {string} formLabel
 * @param {string} message
 */
export const formSubmitErrored = (formId, formType, formLabel, message) => {
  try {
    pushEvent({
      event: "formSubmission",
      form: {
        id: formId,
        type: formType,
        messages: [
          {
            type: "error",
            message: message,
          },
        ],
      },

      eventCategory: "Form",
      formAction: "Submit",
      formLabel: formLabel,
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Push a form submission event
 * @param {string} formId
 * @param {string} formType
 * @param {string} formName
 * @param {string} leadId
 * @param {any} extraData
 */
export const formSubmitSuccess = (
  formId,
  formType,
  formName,
  leadId,
  extraData
) => {
  try {
    pushEvent({
      event: "formSubmission",
      form: {
        id: formId,
        type: formType,
        name: formName,
        leadId: leadId,
      },
      ...extraData,
    })
  } catch (error) {
    console.errog(error)
  }
}

/**
 * Get the Google Analytics Cookie ID
 * @return {string}
 */
export const getGACookie = () => {
  try {
    const tracker = window?.ga.getAll()[0]
    return tracker.get("clientId")
  } catch (e) {
    return undefined
  }
}
