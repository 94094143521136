import React, { FunctionComponent } from "react"
import {
  Legal,
  ComponentLayoutBookingForm,
  CourseType,
} from "../../../types/generated/strapi"
import { useStaticQuery, graphql } from "gatsby"
import BookingForm from "../../sections/BookingForm/BookingForm"

interface BookingFormData {
  site: {
    siteMetadata: {
      formApiBaseUrl?: string
    }
  }
  strapiGqlSource: {
    legal?: Partial<Legal>
    courseTypes?: CourseType[] | undefined
  }
}

const BookingFormLayout: FunctionComponent<ComponentLayoutBookingForm> = ({
  CourseType,
}) => {
  const data: BookingFormData = useStaticQuery(graphql`
    query BookingFormQuery {
      site {
        siteMetadata {
          formApiBaseUrl
        }
      }
      strapiGqlSource {
        legal {
          MarketingConsent
        }
        courseTypes {
          __typename
          _id
          id
          Name
          Price
          IsActive
          Courses {
            id
            Date
            TotalSpaces
            IsActive
            Bookings {
              id
            }
            CourseType {
              id
              Name
            }
          }
        }
      }
    }
  `)

  return (
    <BookingForm
      formApiBaseUrl={data?.site?.siteMetadata?.formApiBaseUrl}
      marketingConsentText={data?.strapiGqlSource?.legal?.MarketingConsent}
      courseTypes={data?.strapiGqlSource?.courseTypes}
      courseType={CourseType}
    />
  )
}

export default BookingFormLayout

export const BookingFormLayoutFragment = graphql`
  fragment BookingFormLayoutFragment on StrapiGQLSource_ComponentLayoutBookingForm {
    __typename
    _id
    id
    AnchorId
    CourseType {
      __typename
      _id
      id
      createdAt
      updatedAt
      IsActive
      Name
      Image {
        ...ImageComponentFragment
      }
      Courses {
        Bookings {
          id
        }
        _id
        Date
        id
        createdAt
        updatedAt
        IsActive
        TotalSpaces
        r_availableSpaces
        r_courseType
        r_totalBookings
      }
    }
  }
`
