/**
 * Format a price using the thousands notation using the replacer or a space
 * @param price
 * @param replacer 
 */
const formatPrice = (price: number, replacer?: string) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replacer || " ")
}
    
export default formatPrice
