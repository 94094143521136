import React, { FunctionComponent } from "react"
import { UploadFile } from "../../../types/generated/strapi"

import { graphql } from "gatsby"
// import Img, { GatsbyImageProps } from "gatsby-image"
import Video from "./Video"

export interface StrapiImageProps extends Partial<UploadFile> {
  className?: string
  /**
   * Set this to true if you would like images to be loaded before they are on
   * the screen. This must be true if your image appears ina  looping slider
   */
  isEagerLoaded?: boolean
  /**
   * If the image should be absolutely positioned. If not stated explicitly
   * this will be derived from the className containing "absolute" to be true
   * or "relative" to be false
   */
  isAbsolutePositioned?: boolean
  shouldShowControls?: boolean
  shouldAutoPlay?: boolean
  shouldBeMuted?: boolean
  shouldLoop?: boolean
  style?: any
  // imageFile?: {
  //   id?: string
  //   name?: string
  //   publicURL?: string
  //   childImageSharp?: GatsbyImageProps
  // }
}

const StrapiImage: FunctionComponent<StrapiImageProps> = ({
  url,
  mime,
  alternativeText,
  className,
  // imageFile,
  isEagerLoaded = false,
  isAbsolutePositioned,
  shouldAutoPlay = false,
  shouldShowControls = true,
  shouldBeMuted = true,
  shouldLoop = false,
  style = {},
}) => {
  if (typeof isAbsolutePositioned === "undefined") {
    isAbsolutePositioned = className?.includes("absolute")
  }

  const loadingProps = isEagerLoaded
    ? {
        imgStyle: {
          opacity: 1,
        },
        placeholderStyle: {
          opacity: 0,
        },
      }
    : {}

  if (mime?.startsWith("video")) {
    return (
      <Video
        className={className || ""}
        controls={shouldShowControls}
        autoplay={shouldAutoPlay}
        muted={shouldBeMuted}
        loop={shouldLoop}
      >
        <source src={url} />
      </Video>
    )
  } else {
    return (
      <img
        src={url}
        alt={alternativeText || ""}
        style={style}
        className={className || ""}
      />
    )
  }
}

export default StrapiImage

export const ImageComponentFragment = graphql`
  fragment ImageComponentFragment on StrapiGQLSource_UploadFile {
    __typename
    id
    mime
    url
    ext
    # imageFile {
    #   __typename
    #   id
    #   publicURL
    #   childImageSharp {
    #     fluid {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
  }
`

export const TracedImageComponentFragment = graphql`
  fragment TracedImageComponentFragment on StrapiGQLSource_UploadFile {
    __typename
    id
    mime
    url
    ext
    # imageFile {
    #   __typename
    #   id
    #   publicURL
    #   childImageSharp {
    #     fluid {
    #       ...GatsbyImageSharpFluid_tracedSVG
    #     }
    #   }
    # }
  }
`
