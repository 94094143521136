import { ComponentLayoutDiagonalSlider } from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import Title from "../../generic/Text/Title"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import { G, R, C } from "../../Workbench/Components"
import ResponsiveLink, {
  LinkDisplay,
} from "../../generic/AnyLink/ResponsiveLink"
import DoubleCTA from "../../generic/AnyLink/DoubleCTA"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import TitleLayout from "../../generic/Text/TitleLayout"
import { WBColorStyle } from "../../../types/Colours"

const DiagonalSlider: FunctionComponent<Partial<
  ComponentLayoutDiagonalSlider
>> = ({ Detail, Title: title, Image, CTAPrimary, CTASecondary }) => {
  const spacer = (
    <C desktopSize={7} tabletSize={4} className="hidden md:block">
      <br />
    </C>
  )

  const videoConfig = {
    shouldBeMuted: true,
    shouldAutoPlay: true,
    shouldShowControls: false,
    shouldLoop: true,
  }
  return Detail || title || Image || CTAPrimary || CTASecondary ? (
    <div className="relative w-full overflow-x-hidden mt-xl">
      <div data-aos="fade" className="relative z-30 md:py-xl">
        <G>
          <R>
            {spacer}
            <C desktopSize={5} tabletSize={8} mobileSize={12}>
              {title ? (
                <TitleLayout {...title} textColor={WBColorStyle.White} />
              ) : null}
            </C>
          </R>
        </G>
        <div
          data-aos="fade"
          data-aos-duration="1000"
          className="mt-xs md:hidden"
        >
          <StrapiImage
            className="h-full w-screen object-cover"
            isAbsolutePositioned={false}
            {...Image}
            {...videoConfig}
          />
        </div>
        <G>
          <R>
            {spacer}
            <C desktopSize={5} tabletSize={8} mobileSize={12}>
              {Detail ? (
                <div className="mt-xs text-wb-color-white">
                  <ParagraphGroup
                    text={Detail}
                    className="text-wb-color-white leading-5"
                    blockLevel="mt-xxs"
                  />
                </div>
              ) : null}
              <div>
                <DoubleCTA primary={CTAPrimary} secondary={CTASecondary} />
              </div>
            </C>
          </R>
        </G>
      </div>

      <div className="absolute hidden md:block z-20 inset-0 overflow-hidden">
        <div className="md:ml-5/12 ml-1/2 h-full transform -skew-x-1/3 bg-wb-color-black bg-opacity-75"></div>
      </div>

      <div
        data-aos="fade"
        data-aos-duration="1000"
        className="absolute z-10 top-0 bottom-0 hidden md:block"
      >
        <StrapiImage
          {...videoConfig}
          className="h-full w-screen object-cover"
          {...Image}
        />
      </div>
    </div>
  ) : null
}

export default DiagonalSlider

export const DiagonalSliderFragment = graphql`
  fragment DiagonalSliderFragment on StrapiGQLSource_ComponentLayoutDiagonalSlider {
    __typename
    _id
    id
    Detail
    AnchorId
    Title {
      ...TitleComponentFragment
    }
    Image {
      ...ImageComponentFragment
    }
    CTAPrimary {
      ...NavigationLinkFragment
    }
    CTASecondary {
      ...NavigationLinkFragment
    }
  }
`
