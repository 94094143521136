import { ComponentLayoutImageGrid } from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { G, R, C } from "../../Workbench/Components"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import "./ImageGrid.css"
import ResponsiveMasonry from "./ResponsiveMasonry"

const ImageGrid: FunctionComponent<Partial<ComponentLayoutImageGrid>> = ({
  Images,
  Size,
}) => {
  return (
    <>
      <div className="block md:hidden">
        <ResponsiveMasonry
          deviceSpan={Size?.MobileSpan || 12}
          images={Images}
        />
      </div>
      <div className="hidden md:block lg:hidden">
        <ResponsiveMasonry deviceSpan={Size?.TabletSpan || 6} images={Images} />
      </div>
      <div className="hidden lg:block">
        <ResponsiveMasonry
          deviceSpan={Size?.DesktopSpan || 4}
          images={Images}
        />
      </div>
    </>
  )
}

export default ImageGrid

export const ImageGridFragment = graphql`
  fragment ImageGridFragment on StrapiGQLSource_ComponentLayoutImageGrid {
    __typename
    _id
    id
    AnchorId
    Images {
      ...ImageComponentFragment
    }
    Size {
      ...SectionSizeFragment
    }
  }
`
