import React, { Component, ErrorInfo } from "react"

export interface ErrorBoundaryProps {
  fallback: JSX.Element | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  public state = {
    hasError: false,
    fallback: null as JSX.Element | null,
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { ...this.state, fallback: props.fallback || null }
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error("Error Boundry Caught Render Error", error, info)
  }
  
  public render() {
    if (this.state.hasError) {
      return this.state.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
