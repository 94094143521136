import { FunctionComponent } from "react"
import {
  Course as CourseModel,
  CourseType as CourseTypeModel,
  Course,
} from "../../../types/generated/strapi"
import { graphql } from "gatsby"
import React from "react"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import formatPrice from "../../../types/formatPrice"
import { toPrettyDate, toDateSafely } from "../../../utils/date"

export interface CourseCardProps extends CourseTypeModel {
  animationStartDelay?: number
}

const CourseCard: FunctionComponent<CourseCardProps> = ({
  Name,
  Price,
  ShortDescription,
  Image,
  Slug,
  Courses,
  Venue,
  animationStartDelay = 0,
  CourseLogo,
}) => {
  const nextCourseDate = getNextCourseDate(Courses as Course[] | undefined)

  return (
    <>
      <div className="relative">
        {Image ? (
          <StrapiImage
            shouldAutoPlay={true}
            shouldShowControls={false}
            shouldBeMuted={true}
            shouldLoop={true}
            className="relative h-full w-full object-cover"
            {...Image}
          />
        ) : null}
        {Name ? (
          <>
            <div
              data-aos="fade"
              data-aos-delay={`${animationStartDelay + 400}`}
              className="absolute w-full bottom-xxs"
            >
              <div className="flex flex-row justify-center max-w-85p m-auto">
                <div className="mr-3xs">
                  {CourseLogo ? (
                    <StrapiImage
                      shouldAutoPlay={true}
                      shouldShowControls={false}
                      shouldBeMuted={true}
                      shouldLoop={true}
                      className="max-w-95"
                      {...CourseLogo}
                    />
                  ) : null}
                </div>
                <div>
                  <div className="flex flex-col">
                    <span className="inline-block text-wb-color-black">
                      <span className="inline-block transform px-xxs -skew-x-1/13 bg-wb-color-white">
                        <span className="inline-block pl-3xs py-3xs transform uppercase">
                          {Name}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className="mt-2">
                    {Price ? (
                      <div className="text-wb-color-white text-m font-demi font-sans text-2xl italic">
                        R {formatPrice(Price)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <div className="text-wb-color-white bg-wb-color-mineshaft p-xs">
        <div className="flex flex-row justify-between items-center mt-xxs">
          <div className="flex flex-row items-center">
            <svg
              className="h-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 58.042 80.432"
            >
              <path
                d="M36.989 56.928l-5.236 23.504h-5.347l-5.235-23.504C8.806 53.363.006 41.667.006 28.969-.328 13.708 13.373-.328 29.08.006c15.595 0 28.962 13.367 28.962 28.962 0 12.588-8.8 24.395-21.053 27.96zm11.584-27.96c0-10.694-8.8-19.494-19.494-19.494-10.582 0-19.605 8.8-19.605 19.494 0 10.582 9.023 19.605 19.605 19.605 10.694.001 19.494-9.022 19.494-19.605zm-15.038 0c0 2.562-1.894 4.456-4.456 4.456s-4.456-1.894-4.456-4.456 1.894-4.456 4.456-4.456c2.563.001 4.456 1.894 4.456 4.456z"
                fill="#FFF"
              />
            </svg>
            <div className="text-m font-demi ml-3xs">{Venue}</div>
          </div>
        </div>
        {ShortDescription ? (
          <div className="mt-xs font-sans">{ShortDescription}</div>
        ) : null}

        <AnyLink
          className="block mt-xs"
          to={Slug || "#"}
          linkStyle={LinkStyle.WhiteWithChevron}
        >
          More Information
        </AnyLink>
      </div>
    </>
  )
}

export default CourseCard

export const getNextCourseDate = (courses?: Course[]): string | undefined => {
  return getNextCourse(courses)?.Date
}

export const getNextCourse = (courses?: Course[]): Course | undefined => {
  const valueOfOrZero = (dateString?: string) => {
    return toDateSafely(dateString)?.valueOf() || 0
  }
  return courses
    ?.filter(c => typeof c?.Date !== "undefined")
    .filter(c => valueOfOrZero(c?.Date) >= Date.now().valueOf())
    .sort((a, b) => {
      return valueOfOrZero(a?.Date) - valueOfOrZero(b?.Date)
    })[0]
}

export const CourseModelFragment = graphql`
  fragment CourseModelFragment on StrapiGQLSource_CourseType {
    __typename
    id
    Duration
    Name
    Price
    Venue
    Description
    ShortDescription
    IsActive
    Slug
    Courses {
      id
      Date
    }
    Image {
      ...ImageComponentFragment
    }
    CourseLogo {
      ...ImageComponentFragment
    }
    Agenda {
      Description
      Time
      id
    }
  }
`
