import { ComponentLayoutTwoColumnTextOnly } from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { G, R, C } from "../../Workbench/Components"
import Title from "../../generic/Text/Title"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import TitleLayout from "../../generic/Text/TitleLayout"
import { WBColorStyle } from "../../../types/Colours"

const TwoColumnTextOnly: FunctionComponent<Partial<
  ComponentLayoutTwoColumnTextOnly
>> = ({ Title: title, Text1, Text2, HasSpacing = true }) => {
  return (
    <G className={HasSpacing ? "mt-xl" : "mt-s"}>
      {title ? (
        <R>
          <C
            desktopSize={12}
            tabletSize={12}
            smallTabletSize={12}
            mobileSize={12}
          >
            <TitleLayout
              {...title}
              textColor={WBColorStyle.White}
              useDefaultAnimation={true}
            />
          </C>
        </R>
      ) : null}
      <R>
        <C desktopSize={6} tabletSize={6} smallTabletSize={12} mobileSize={12}>
          <ParagraphGroup text={Text1} useAnimation={true} />
        </C>
        <C
          desktopSize={6}
          tabletSize={6}
          mobileSize={12}
          className="mt-xs sm:mt-0"
        >
          <ParagraphGroup text={Text2} useAnimation={true} />
        </C>
      </R>
    </G>
  )
}

export default TwoColumnTextOnly

export const TwoColumnTextOnlyFragment = graphql`
  fragment TwoColumnTextOnlyFragment on StrapiGQLSource_ComponentLayoutTwoColumnTextOnly {
    __typename
    _id
    id
    AnchorId
    Text1
    Text2
    HasSpacing
    Title {
      ...TitleComponentFragment
    }
  }
`
