import React, { FunctionComponent } from "react"
import { UploadFile } from "../../../types/generated/strapi"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import { G } from "../../Workbench/Components"
import { sortByColumnFlow } from "../../../utils/sortByColumnFlow"

interface ResponsiveMasonryProps {
  images?: Array<UploadFile | undefined>
  deviceSpan: number
}

export const getColsFromDeviceSpan = (span: number) => {
  switch (span) {
    case 1:
      return 12
    case 2:
      return 6
    case 3:
      return 4
    case 4:
      return 3
    case 5:
      return 2
    case 6:
      return 2
    default:
      return 1
  }
}

const ResponsiveMasonry: FunctionComponent<ResponsiveMasonryProps> = ({
  deviceSpan,
  images,
}) => {
  let cols: number = getColsFromDeviceSpan(deviceSpan)

  const sortedImages = sortByColumnFlow<UploadFile | undefined>(
    images || [],
    cols
  )

  return sortedImages ? (
    <G className="mt-xl flex flex-row">
      {sortedImages?.map((colImages, c) => (
        <div className="px-xxs flex-1" key={c}>
          {colImages?.map((image, i) => (
            <div
              data-aos="fade"
              data-aos-delay={i * 100}
              className="py-xxs"
              key={i}
            >
              <StrapiImage
                shouldAutoPlay={true}
                shouldBeMuted={true}
                shouldLoop={true}
                shouldShowControls={false}
                {...image}
                className="w-full h-auto object-contain"
              />
            </div>
          ))}
        </div>
      ))}
    </G>
  ) : null
}

export default ResponsiveMasonry
