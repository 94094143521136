import React, { FunctionComponent, useState } from "react"
import Title from "../../generic/Text/Title"
import { WBColorStyle } from "../../../types/Colours"
import { G, R, C } from "../../Workbench/Components"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import PersonalDetailFields, {
  PersonalDetails,
} from "../../generic/Form/PersonalDetailsFields"
import {
  FormClient,
  ExternalLeadDto,
  Voucher,
} from "../../../types/generated/webApi"
import getPageMetadata from "../../../types/getPageMetadata"
import LoadingState from "../../generic/Form/LoadingState"
import AnimatedEllipse from "../../generic/Form/AnimatedEllipse"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import RadioGroup from "../../generic/Form/RadioGroup"
import isFieldValid, { matchers } from "../../../utils/isFieldValid"
import convertPersonalDetailsToCustomer from "../../../types/convertPersonalDetailsToCustomer"
import ArrowLeft from "wb2-assets/dist/icons/arrow-left.svg"

export interface ContactFormProps {
  marketingConsentText?: string
  formApiBaseUrl?: string
}

enum Step {
  One = 1,
  Two = 2,
}

export interface FormData extends PersonalDetails {
  isValid?: boolean
}

const VoucherForm: FunctionComponent<ContactFormProps> = ({
  marketingConsentText,
  formApiBaseUrl,
}) => {
  const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Initial
  )
  const [customerByData, setCustomerByData] = useState<FormData>({
    isValid: false,
  })
  const [customerForData, setCustomerForData] = useState<FormData>({
    isValid: false,
  })
  const [marketingConsent, setMarketingConsent] = useState<boolean | undefined>(
    undefined
  )

  const [step, setStep] = useState<Step>(Step.One)

  const showConsentError = () => {
    var isValid = isFieldValid(matchers.marketingConsent, marketingConsent)
    return !isValid && shouldShowErrors
  }

  const handleSubmit = async () => {
    const isFormValid =
      customerByData.isValid &&
      customerForData.isValid &&
      typeof marketingConsent !== "undefined"

    setShouldShowErrors(true)
    if (isFormValid) {
      setLoadingState(LoadingState.IsLoading)
      const formClient = new FormClient(formApiBaseUrl)

      try {
        const pageMeta = getPageMetadata()

        const submissionResult = await formClient.voucher({
          customerBy: convertPersonalDetailsToCustomer(customerByData),
          customerFor: convertPersonalDetailsToCustomer(customerForData),
          // type casting due to missing fields in the Customer as it expects an
          // object instance and not just the stucture. However, the stuct is ok
        } as Voucher)

        if (submissionResult.success) {
          setLoadingState(LoadingState.Done)
        } else {
          setLoadingState(LoadingState.HasError)
        }
      } catch (error) {
        console.error(error)
        setLoadingState(LoadingState.HasError)
      }
    }
  }

  const wrapperClass = "mt-xl pb-m pt-s bg-wb-color-mineshaft"

  return loadingState === LoadingState.Done ? (
    <div className={wrapperClass}>
      <G>
        <R className="">
          <C desktopSize={12} tabletSize={12} mobileSize={12}>
            <Title
              text="Form Submitted"
              subtext="Contact Us"
              textColor={WBColorStyle.White}
              useDefaultAnimation={true}
            />
            <p className=" mt-xs text-wb-color-white leading-7">
              Your form has been submitted. Someone from the AMG Driving Academy
              will be in touch soon.
            </p>
          </C>
        </R>
      </G>
    </div>
  ) : (
    <form
      data-aos="fade"
      className={wrapperClass}
      noValidate={true}
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <G>
        <R className="">
          <C desktopSize={12} tabletSize={12} mobileSize={12}>
            <Title
              text={step === Step.One ? "Personal Details" : "Voucher Details"}
              subtext={`Online Voucher | ${step}`}
              textColor={WBColorStyle.White}
            />
          </C>
        </R>
      </G>
      {step === Step.One ? (
        <>
          <PersonalDetailFields
            value={customerByData}
            onValueChange={personalDetails => {
              setCustomerByData({ ...customerByData, ...personalDetails })
            }}
            shouldShowErrors={shouldShowErrors}
            loadingState={loadingState}
          />
          <G className="mt-xs">
            <R>
              <C>
                <ParagraphGroup
                  text={marketingConsentText}
                  className="text-wb-color-white"
                />
                <div className="mt-xxs">
                  <RadioGroup
                    value={marketingConsent}
                    isHorizontal={true}
                    onChange={(marketingConsent?: boolean) =>
                      setMarketingConsent(marketingConsent)
                    }
                    options={[
                      { name: "Yes", label: "Yes", value: true },
                      { name: "No", label: "No", value: false },
                    ]}
                    error="Please select an option"
                    hasError={showConsentError()}
                    isDisabled={loadingState === LoadingState.IsLoading}
                  />
                </div>
              </C>
            </R>
          </G>
        </>
      ) : (
        <PersonalDetailFields
          value={customerForData}
          optionalFields={{
            postalCode: true,
          }}
          onValueChange={personalDetails => {
            setCustomerForData({ ...customerForData, ...personalDetails })
          }}
          shouldShowErrors={shouldShowErrors}
          loadingState={loadingState}
        />
      )}

      <G className="mt-s">
        <R>
          <C>
            {step === Step.One ? (
              <AnyLink
                useButton={true}
                buttonType="submit"
                linkStyle={LinkStyle.PrimaryButton}
                onButtonClick={() => {
                  if (
                    customerByData.isValid === true &&
                    typeof marketingConsent !== "undefined"
                  ) {
                    setStep(Step.Two)
                  } else {
                    setShouldShowErrors(true)
                  }
                }}
                isButtonDisabled={loadingState === LoadingState.IsLoading}
                iconRight={true}
              >
                Next
              </AnyLink>
            ) : null}

            {step === Step.Two ? (
              <AnyLink
                useButton={true}
                buttonType="button"
                linkStyle={LinkStyle.SecondaryButton}
                onButtonClick={() => {
                  setStep(Step.One)
                }}
                isButtonDisabled={loadingState === LoadingState.IsLoading}
                iconSrc={ArrowLeft}
              >
                Previous
              </AnyLink>
            ) : null}

            {step === Step.Two ? (
              <AnyLink
                useButton={true}
                buttonType="submit"
                linkStyle={LinkStyle.PrimaryButton}
                onButtonClick={() => {
                  handleSubmit()
                }}
                isButtonDisabled={loadingState === LoadingState.IsLoading}
                iconRight={true}
              >
                {loadingState === LoadingState.IsLoading ? (
                  <span>
                    Loading <AnimatedEllipse />
                  </span>
                ) : (
                  "Contact Me"
                )}
              </AnyLink>
            ) : null}
          </C>
        </R>
      </G>
      {loadingState === LoadingState.HasError ? (
        <G className="mt-xs">
          <R>
            <C>
              <p className="text-wb-color-error leading-7">
                An error occured. Please try again.
              </p>
            </C>
          </R>
        </G>
      ) : null}
    </form>
  )
}

export default VoucherForm
