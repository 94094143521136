import { getGACookie } from "../utils/dataLayer"

const getPageMetadata = (): {
  csref?: string
  url?: string
  gaCookie?: string
} => {
  if (typeof window === "undefined") return {}

  const url = window?.location?.href

  const params = new URLSearchParams(window?.location?.search)

  const csref = params?.get("csref") || undefined

  const gaCookie = getGACookie()

  return {
    csref,
    url,
    gaCookie,
  }
}

export default getPageMetadata
