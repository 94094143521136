import "./Table.css"
import React, { FunctionComponent } from "react"
import { WBFontStyle } from "../../../types/Fonts"
import optionalAnimationAttributes from "../../../utils/optionalAnimationAttributes"

export enum HeadingPosition {
  Top,
  Left,
}

export type TableProps = {
  className?: string
  headingPosition?: HeadingPosition
  headings?: string[]
  rows?: (string | number | undefined)[][]
  useAnimation?: boolean
}

const Table: FunctionComponent<TableProps> = ({
  headingPosition = HeadingPosition.Top,
  headings = [],
  rows = [[]],
  className = "",
  useAnimation = false,
}) => {
  return (
    <table className={`table ${className}`}>
      {headingPosition == HeadingPosition.Top ? (
        <thead>
          <tr className="table__row wb2-type-copy-strong">
            {headings.map((heading, headingIndex) => (
              <th key={headingIndex} className="table__cell">
                {heading}
              </th>
            ))}
          </tr>
        </thead>
      ) : null}
      <tbody>
        {headingPosition == HeadingPosition.Top
          ? rows.map((row, rowIndex) => (
              <tr
                {...optionalAnimationAttributes(
                  useAnimation ? "fade-up" : undefined,
                  rowIndex * 20
                )}
                key={rowIndex}
                className="table__row wb2-type-copy"
              >
                {row.map((cell, colIndex) => (
                  <td key={colIndex} className="table__cell">
                    {cell}
                  </td>
                ))}
              </tr>
            ))
          : rows.map((row, rowIndex) => (
              <tr
                {...optionalAnimationAttributes(
                  useAnimation ? "fade-right" : undefined,
                  rowIndex * 20
                )}
                key={rowIndex}
                className="table__row wb2-type-copy"
              >
                {row.map((cell, colIndex) =>
                  colIndex == 0 ? (
                    [
                      <th
                        key="header"
                        className="table__cell  wb2-type-copy-strong relative"
                      >
                        {headings[rowIndex]}
                        <span className="absolute block -right-3xs top-0 w-xxs z-0 h-full bg-wb-color-mineshaft transform -skew-x-1/13"></span>
                      </th>,
                      <td key={colIndex} className="table__cell bg-wb-color-gunmetal relative ml-s">
                        <span className="inline-block w-xxs"></span>
                        <span className="absolute block -left-xxs top-0 w-xs z-0 h-full bg-wb-color-gunmetal transform -skew-x-1/13"></span>
                        <span className="relative z-20">{cell}</span>
                      </td>,
                    ]
                  ) : (
                    <td key={colIndex} className="table__cell">
                      {cell}
                    </td>
                  )
                )}
              </tr>
            ))}
      </tbody>
    </table>
  )
}
export default Table
