import { FunctionComponent } from "react"
import { CourseType as CourseTypeModel } from "../../../types/generated/strapi"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import CourseListing from "../../sections/CourseListing/CourseListing"

type CourseListingLayoutData = {
  strapiGqlSource: {
    courseTypes?: CourseTypeModel[]
  }
}

const CourseListingLayout: FunctionComponent = () => {
  const data: CourseListingLayoutData = useStaticQuery(graphql`
    query {
      strapiGqlSource {
        courseTypes {
          ...CourseModelFragment
        }
      }
    }
  `)

  return <CourseListing data={data?.strapiGqlSource?.courseTypes} />
}

export default CourseListingLayout

export const AvailableCourseListingFragment = graphql`
  fragment AvailableCourseListingFragment on StrapiGQLSource_ComponentLayoutAvailableCourseListing {
    id
    __typename
    AnchorId
  }
`
