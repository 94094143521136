export type GalleryLayout = {
  leftSize?: { desktop?: number; tablet?: number }
  rightSize?: { desktop?: number; tablet?: number }
}

export const resolveLayout = (imageCount: number) => {
  let layout: GalleryLayout
  /***
   * grid sizes for for 2 left and right side of gallery layout
   * left side 12, 7, 8, 7
   * right side 5, 4, 5
   */

  // TODO: return named type
  switch (imageCount) {
    case 1:
      layout = {
        leftSize: {
          desktop: 12,
          tablet: 12,
        },
      }
      break
    case 3:
      layout = {
        leftSize: {
          desktop: 8,
          tablet: 8,
        },
        rightSize: {
          desktop: 4,
          tablet: 4,
        },
      }
      break
    default:
      layout = {
        leftSize: {
          desktop: 7,
          tablet: 7,
        },
        rightSize: {
          desktop: 5,
          tablet: 5,
        },
      }
  }
  return layout
}
