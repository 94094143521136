import {
  Component,
  FunctionComponent,
  useRef,
  createRef,
  createElement,
  useEffect,
} from "react"
import React from "react"

export interface VideoProps {
  className?: string
  /**
   * `muted` should be true when using this to avoid potential issues
   */
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  controls?: boolean
}

const Video: FunctionComponent<VideoProps> = ({
  className = "",
  autoplay = true,
  muted = false,
  loop = false,
  controls = false,
  children,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    if (videoElement) {
      videoElement.setAttribute("muted", muted ? "true" : "false")
    }
  }, [])

  return (
    <video
      width="100%"
      className={className || ""}
      ref={videoRef}
      autoPlay={autoplay}
      loop={loop}
      controls={controls}
      muted={muted}
    >
      {children}
    </video>
  )
}

export default Video
