/**
 * Months as defined by the JS Date Class, `index = date.getMonth()`
 */
 export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

/**
 * Days as defined by the JS Date Class, `index = date.getDay()`
 */
export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

/**
 * Days as defined by the JS Date Class, `index = date.getDay()`
 */
export const fullDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

/**
 * Will convert to a date or return undefined if date is not valid
 * @param dateString
 */
export const toDateSafely = (dateString?: string | null): Date | undefined => {
  if (typeof dateString === "undefined" || dateString === null) return undefined

  const date = new Date(dateString)

  if (isNaN(date.valueOf())) {
    return undefined
  } else {
    return date
  }
}

/**
 * Convert a Date String to Full Date Text as `22 November 2020`, will return
 * undefined if date is invalid
 * @param dateString
 */
export const toFullDateText = (
  dateString?: string | null
): string | undefined => {
  const date = toDateSafely(dateString)

  if (date) {
    return toPrettyDate(date)
  } else {
    return undefined
  }
}

/**
 * pretty print a date object
 * @param date
 */
export const toPrettyDate = (date: Date) => {
  const dateMeta = getDateMeta(date)
  return `${dateMeta.day} ${dateMeta.monthLong} ${dateMeta.year}`
}

export const getDateMeta = (date: Date) => {
  return {
    month: date.getMonth(),
    monthLong: months[date.getMonth()],
    day: date.getDate(),
    year: date.getFullYear(),
    dayOfWeekShort: days[date.getDay()],
    dayOfWeekLong: fullDays[date.getDay()],
  }
}
