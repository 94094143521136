import getPageMetadata from "./getPageMetadata"

const cookieName = "MBSA_Domain_CSRef"

const setCsrefCookie = () => {
  try {
    if (
      typeof window !== "undefined" &&
      typeof document !== "undefined" &&
      typeof location !== "undefined"
    ) {
      const pageMeta = getPageMetadata()

      if (typeof pageMeta?.csref !== "undefined") {
        setCookie(cookieName, pageMeta.csref, 30)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export default setCsrefCookie

const getCookie = (cname: string) => {
  try {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  } catch (error) {
    console.error(error)
    return ""
  }
}

const setCookie = (cname: string, cvalue: string, exdays: number) => {
  try {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = "expires=" + d.toUTCString()
    const domain = getDomain()

    if (typeof domain === "undefined") {
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
    } else {
      document.cookie =
        cname + "=" + cvalue + expires + "; path=/; domain=" + domain

      // check if cookie was successfuly set to the given domain
      // (otherwise it was a Top-Level Domain)
      if (getCookie(cname) == null || getCookie(name) != cvalue) {
        // append "." to current domain
        let domain = "." + location.host
        document.cookie =
          cname + "=" + cvalue + expires + "; path=/; domain=" + domain
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const getDomain = () => {
  let host = location.host
  if (host.split(".").length === 1) {
    // no "." in a domain - it's localhost or something similar
    return undefined
  } else {
    // Remember the cookie on all subdomains.
    //
    // Start with trying to set cookie to the top domain.
    // (example: if user is on foo.com, try to set
    //  cookie to domain ".com")
    //
    // If the cookie will not be set, it means ".com"
    // is a top level domain and we need to
    // set the cookie to ".foo.com"
    let domainParts = host.split(".")
    domainParts.shift()
    let domain = "." + domainParts.join(".")

    return domain
  }
}
