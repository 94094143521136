import "swiper/css/swiper.css"
import "./Slider.css"

import React, { FunctionComponent } from "react"
import Swiper, { ReactIdSwiperProps } from "react-id-swiper"

const Slider: FunctionComponent<ReactIdSwiperProps> = props => {
  return (
    <>
      <Swiper
        key={props.slidesPerView || 0}
        {...props}
        rebuildOnUpdate={true}
        grabCursor={true}
      >
        {props.children}
      </Swiper>
    </>
  )
}
export default Slider
