import React, { FunctionComponent } from "react"
import LoadingState from "../../generic/Form/LoadingState"
import { G, R, C } from "../../Workbench/Components"
import { CourseType, Course } from "../../../types/generated/strapi"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import { convertNumberToStringWithSpaces } from "../../../types/thousandSeperatorFormatter"
import { getDateMeta } from "../../../utils/date"
import { sortedCourseTypesByLevel } from "../BookingForm/stepOneConverisons"


import "./BookingForm.css"
import {
  getOrdinalNum,
  convertToMonthYear,
  filterCoursesByMonthAndYear,
} from "./stepOneConverisons"

export type StepOneDetails = {
  isValid?: boolean
  courseType?: CourseType
  course?: Course
}

export type StepOneProps = {
  value: StepOneDetails
  shouldShowErrors?: boolean
  onValueChange: (newValue?: StepOneDetails) => void
  loadingState?: LoadingState
  courseTypes?: CourseType[] | undefined
}

export type CourseByMonthYear = {
  monthYear?: string
  courses?: Course[]
}


const StepOne: FunctionComponent<StepOneProps> = ({
  value,
  shouldShowErrors,
  onValueChange,
  loadingState = LoadingState.Initial,
  courseTypes,
}) => {
  const isDisabled = loadingState === LoadingState.IsLoading

  const handleFieldChange = (newValue?: StepOneDetails) => {
    const courseContainedInListing =
      newValue?.courseType?.Courses?.filter(
        c => c?.id === newValue?.course?.id
      ) || []

    if (newValue?.course && courseContainedInListing.length === 0) {
      newValue.course = undefined
    }

    const isValid =
      typeof newValue?.course !== "undefined" && newValue?.course !== null

    shouldShowErrors = !isValid

    onValueChange({ ...newValue, isValid })
  }

  const sortedCourseTypes = sortedCourseTypesByLevel(courseTypes)
  const CourseCard: FunctionComponent<{
    course: Course
  }> = ({ course }) => {
    return (
      <>
        {course ? (
          <AnyLink
            buttonType="button"
            linkStyle={
              course?.id === value?.course?.id
                ? LinkStyle.PrimaryButton
                : LinkStyle.SecondaryButton
            }
            isButtonDisabled={
              isDisabled ||
              (course?.Bookings &&
                course?.TotalSpaces &&
                course?.Bookings?.length >= course?.TotalSpaces)
                ? true
                : false
            }
            useButton={true}
            showIcon={false}
            className={`flex flex-col flex-wrap justify-between mt-xxs mr-3xs ${
              course?.TotalSpaces &&
              course?.Bookings &&
              course?.TotalSpaces - course?.Bookings?.length === 0
                ? "cursor-not-allowed hover:bg-wb-color-white hover:text-wb-color-obsidian"
                : "cursor-pointer"
            }`}
            onButtonClick={() => {
              handleFieldChange({ ...value, course })
            }}
          >
            <span className="mt-xxs pr-s whitespace-no-wrap">
              {`${getOrdinalNum(getDateMeta(new Date(course?.Date)).day)}, ${
                getDateMeta(new Date(course?.Date)).dayOfWeekLong
              }`}
            </span>
            <span className="mt-xxs font-bold pb-xxs whitespace-no-wrap">
              {course?.TotalSpaces &&
              course?.Bookings &&
              course?.TotalSpaces - course?.Bookings?.length > 0
                ? `${course?.TotalSpaces - course?.Bookings?.length} Spots Left`
                : course?.TotalSpaces &&
                  course?.Bookings &&
                  course?.TotalSpaces - course?.Bookings?.length === 0
                ? "Fully Booked"
                : null}
            </span>
          </AnyLink>
        ) : null}
      </>
    )
  }

  return (
    <G>
      <R>
        {sortedCourseTypes  ? (
          <C desktopSize={10} tabletSize={12} mobileSize={12}>
            <div className="line-before mt-s flex flex-wrap pl-xs justify-between">
              {courseTypes?.map((courseType, index) => (
                <div key={index} className="w-full md:w-1/2 pr-xxs">
                  {/* <AnyLink
                    buttonType="button"
                    linkStyle={
                      courseType?.id === value?.courseType?.id
                        ? LinkStyle.PrimaryButton
                        : LinkStyle.SecondaryButton
                    }
                    useButton={true}
                    showIcon={false}  
                    isButtonDisabled={isDisabled}
                    className={`flex flex-wrap justify-between w-full mt-xxs mr-3xs py ${
                      Math.abs(index % 2) === 1 ? "md:w-full" : "md:w-11/12"
                    }`}
                    onButtonClick={() => {
                      handleFieldChange({ ...value, courseType })
                    }}
                  >
                    <span>{courseType.Name}</span>
                    <span className="ml-s">
                      R {convertNumberToStringWithSpaces(courseType.Price)}
                    </span>
                  </AnyLink> */}
                  <AnyLink
                    linkStyle={LinkStyle.Unstyled}
                    useButton={true}
                    onButtonClick={() => {
                      handleFieldChange({ ...value, courseType })
                    }}
                    className="w-full strict-no-border"
                  >
                    <div className="flex mb-xs">
                      <div
                        className={`relative ${
                          courseType?.id === value?.courseType?.id
                            ? "bg-wb-color-amg"
                            : "bg-wb-color-white"
                        }  py-xxs pl-xxs flex-grow`}
                      >
                        <span
                          className={
                            courseType?.id === value?.courseType?.id
                              ? "text-wb-color-white"
                              : "text-wb-color-obsidian"
                          }
                        >
                          {courseType.Name}
                        </span>
                        <span
                          className={`absolute block -right-3xs top-0 w-xxs z-0 h-full ${
                            courseType?.id === value?.courseType?.id
                              ? "bg-wb-color-amg"
                              : "bg-wb-color-white"
                          } transform -skew-x-1/13`}
                        ></span>
                      </div>
                      <div className="bg-wb-color-mineshaft custom-spacer"></div>
                      <div className="relative bg-wb-color-amg py-xxs px-xxs">
                        <span className="text-wb-color-white">
                          R {convertNumberToStringWithSpaces(courseType.Price)}
                        </span>
                        <span className="absolute block -left-3xs top-0 w-xxs z-0 h-full bg-wb-color-amg transform -skew-x-1/13"></span>
                      </div>
                    </div>
                  </AnyLink>
                </div>
              ))}
            </div>
          </C>
        ) : (
          <C desktopSize={10} tabletSize={12} mobileSize={12}>
            <p className=" mt-xs text-wb-color-amg leading-7">
              There are no upcoming courses at the moment.
            </p>
          </C>
        )}
      </R>

      <R>
        <C desktopSize={10} tabletSize={12} mobileSize={12}>
          <div className="scroll flex flex-no-wrap mt-s overflow-x-auto">
            {value?.courseType?.Courses &&
            value?.courseType.Courses.length > 0 ? (
              filterCoursesByMonthAndYear(value?.courseType?.Courses)?.map(
                (courseByDate, i) => (
                  <div className=" line-before flex-grow  pl-xs pb-s" key={i}>
                    <h4 className="text-lg font-demi text-wb-color-white">
                      {courseByDate.monthYear}
                    </h4>
                    <div className="mt-xxs flex flex-no-wrap text-wb-color-white">
                      {courseByDate?.courses
                        ?.filter((c, i) => i % 2 === 0)
                        ?.map((course, index) => (
                          <CourseCard key={index} course={course} />
                        ))}
                    </div>
                    <div className="mt-xxs flex flex-no-wrap">
                      {courseByDate?.courses
                        ?.filter((c, i) => i % 2 === 1)
                        ?.map((course, index) => (
                          <CourseCard key={index} course={course} />
                        ))}
                    </div>
                  </div>
                )
              )
            ) : value?.courseType ? (
              <p className=" mt-xs text-wb-color-amg leading-7">
                There are no upcoming events available for the selected course,
                please select another course
              </p>
            ) : null}
          </div>
          {shouldShowErrors && !value.isValid ? (
            <span className="block mt-xs text-wb-color-error text-sm">
              Please select a course and date
            </span>
          ) : null}
        </C>
      </R>
    </G>
  )
}

export default StepOne


