import { PersonalDetails } from "../components/generic/Form/PersonalDetailsFields"
import {
  Customer,
  Titleship,
  ENUM_CUSTOMER_TITLE as CustomerTitle,
} from "./generated/webApi"

/**
 * Convert DLP title to Strapi title
 * @param title
 */
export const convertTitleShipToTitle = (
  title?: Titleship
): CustomerTitle | undefined => {
  switch (title) {
    case Titleship.Ms:
      return CustomerTitle.Ms
    case Titleship.Mr:
      return CustomerTitle.Mr
    case Titleship.Dr:
      return CustomerTitle.Dr
    case Titleship.Mrs:
      return CustomerTitle.Mrs
    case Titleship.Prof:
      return CustomerTitle.Prof
    default:
      return undefined
  }
}

/**
 * Convert PersonalDetailsForm to DLP Customer
 * @param personalDetail
 * @param marketingConsent
 * @param marketingConsentText
 */

const convertPersonalDetailsToCustomer = (
  personalDetail: PersonalDetails,
  marketingConsent?: boolean,
  marketingConsentText?: string
): Partial<Customer> => {
  return {
    title: convertTitleShipToTitle(personalDetail.title),
    firstName: personalDetail.firstName,
    lastName: personalDetail.lastName,
    email: personalDetail.email,
    phone: personalDetail.phone,
    postalCode: personalDetail.postalCode,
    marketingConsent: marketingConsent,
    marketingConsentText: marketingConsentText,
  }
}

export default convertPersonalDetailsToCustomer
