import React, { FunctionComponent, useState } from "react"
import Title from "../../generic/Text/Title"
import { WBColorStyle } from "../../../types/Colours"
import { G, R, C } from "../../Workbench/Components"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import PersonalDetailFields, {
  PersonalDetails,
} from "../../generic/Form/PersonalDetailsFields"
import { FormClient, ExternalLeadDto } from "../../../types/generated/webApi"
import getPageMetadata from "../../../types/getPageMetadata"
import LoadingState from "../../generic/Form/LoadingState"
import AnimatedEllipse from "../../generic/Form/AnimatedEllipse"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import RadioGroup from "../../generic/Form/RadioGroup"
import isFieldValid, { matchers } from "../../../utils/isFieldValid"

export interface ContactFormProps {
  marketingConsentText?: string
  formApiBaseUrl?: string
}

export interface FormData extends PersonalDetails {
  isValid?: boolean
}

const ContactForm: FunctionComponent<ContactFormProps> = ({
  marketingConsentText,
  formApiBaseUrl,
}) => {
  const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Initial
  )
  const [personalData, setPersonalData] = useState<FormData>({ isValid: false })
  const [marketingConsent, setMarketingConsent] = useState<boolean | undefined>(
    undefined
  )

  const showConsentError = () => {
    var isValid = isFieldValid(matchers.marketingConsent, marketingConsent)
    return !isValid && shouldShowErrors
  }

  const isFormValid =
    personalData.isValid && typeof marketingConsent !== "undefined"

  const handleSubmit = async () => {
    setShouldShowErrors(true)
    if (isFormValid) {
      setLoadingState(LoadingState.IsLoading)
      const formClient = new FormClient(formApiBaseUrl)

      try {
        const pageMeta = getPageMetadata()

        const submissionResult = await formClient.contactMe({
          serviceType: "ADA-Contact-Us",
          customerTitle: personalData.title,
          customerName: personalData.firstName,
          customerSurname: personalData.lastName,
          customerEmail: personalData.email,
          customerMobileNumber: personalData.phone,
          postalCode: personalData.postalCode,
          sourceUrl: pageMeta.url,
          csref: pageMeta.csref,
          cookieId: pageMeta.gaCookie,
          marketingConsent: marketingConsent,
          marketingConsentText: marketingConsentText,
        } as ExternalLeadDto)

        if (submissionResult.success) {
          setLoadingState(LoadingState.Done)
        } else {
          setLoadingState(LoadingState.HasError)
        }
      } catch (error) {
        console.error(error)
        setLoadingState(LoadingState.HasError)
      }
    }
  }

  const wrapperClass = "mt-xl pb-m pt-s bg-wb-color-mineshaft"

  return loadingState === LoadingState.Done ? (
    <div className={wrapperClass}>
      <G>
        <R className="">
          <C desktopSize={12} tabletSize={12} mobileSize={12}>
            <Title
              text="Form Submitted"
              subtext="Contact Us"
              textColor={WBColorStyle.White}
              useDefaultAnimation={true}
            />
            <p className=" mt-xs text-wb-color-white leading-7">
              Your form has been submitted. Someone from the AMG Driving Academy
              will be in touch soon.
            </p>
          </C>
        </R>
      </G>
    </div>
  ) : (
    <form
      data-aos="fade"
      className={wrapperClass}
      noValidate={true}
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <G>
        <R className="">
          <C desktopSize={12} tabletSize={12} mobileSize={12}>
            <Title
              text="Contact Us"
              subtext="AMG Driving Academy"
              textColor={WBColorStyle.White}
            />
          </C>
        </R>
      </G>
      <PersonalDetailFields
        value={personalData}
        onValueChange={personalDetails => {
          setPersonalData({ ...personalData, ...personalDetails })
        }}
        shouldShowErrors={shouldShowErrors}
        loadingState={loadingState}
      />
      <G className="mt-xs">
        <R>
          <C>
            <ParagraphGroup
              text={marketingConsentText}
              className="text-wb-color-white"
            />
            <div className="mt-xxs">
              <RadioGroup
                value={marketingConsent}
                isHorizontal={true}
                onChange={(marketingConsent?: boolean) =>
                  setMarketingConsent(marketingConsent)
                }
                options={[
                  { name: "Yes", label: "Yes", value: true },
                  { name: "No", label: "No", value: false },
                ]}
                error="Please select an option"
                hasError={showConsentError()}
                isDisabled={loadingState === LoadingState.IsLoading}
              />
            </div>
          </C>
        </R>
      </G>
      <G className="mt-s">
        <R>
          <C>
            <AnyLink
              useButton={true}
              buttonType="submit"
              linkStyle={LinkStyle.PrimaryButton}
              onButtonClick={() => {
                handleSubmit()
              }}
              isButtonDisabled={loadingState === LoadingState.IsLoading}
            >
              {loadingState === LoadingState.IsLoading ? (
                <span>
                  Loading <AnimatedEllipse />
                </span>
              ) : (
                "Contact Me"
              )}
            </AnyLink>
          </C>
        </R>
      </G>
      {loadingState === LoadingState.HasError ? (
        <G className="mt-xs">
          <R>
            <C>
              <p className="text-wb-color-error leading-7">
                An error occured. Please try again.
              </p>
            </C>
          </R>
        </G>
      ) : null}
    </form>
  )
}

export default ContactForm
