import { FunctionComponent, useState } from "react"
import {
  CourseType,
  Vehicle,
  ENUM_VEHICLE_BODY as BodyType,
} from "../../../types/generated/strapi"
import ResponsiveSlider from "../../generic/Slider/ResponsiveSlider"
import ModelCard from "./ModelCard"
import React from "react"
import { G, R, C } from "../../Workbench/Components"
import Title from "../../generic/Text/Title"

export const prettifyBodyType = (bodyType: BodyType) => {
  switch (bodyType) {
    case BodyType.Coupe:
      return "Coupé"

    case BodyType.OffRoader:
      return "Off-roader"

    case BodyType.SportsTourer:
      return "Sports Tourer"

    default:
      return bodyType.toString()
  }
}

export type ModelOverviewProps = {
  data?: Vehicle[]
}

const ModelOverview: FunctionComponent<ModelOverviewProps> = ({ data }) => {
  const [bodyFilter, setBodyFilter] = useState<BodyType | undefined>(undefined)

  let bodyTypes = getBodyTypeArray().filter(t =>
    data?.map(v => v.Body)?.includes(t.type)
  )

  const Pill: FunctionComponent<{
    displayName: string
    type?: BodyType
    isActive: boolean
  }> = ({ displayName, type, isActive }) => (
    <button
      className={
        "mr-xxs mt-xxs px-3 py-1 text-sm text-wb-color-white transform -skew-x-1/3 border border-solid border-wb-color-white focus:outline-none hover:bg-wb-color-amg focus:bg-wb-color-amg focus:shadow-default hover:shadow-default hover:border-wb-color-amg focus:border-wb-color-amg transition-all transition-1/5 " +
        (isActive ? "bg-wb-color-amg border-wb-color-amg" : "")
      }
      onClick={() => setBodyFilter(type)}
    >
      <span className="inline-block transform skew-x-1/3 ">{displayName}</span>
    </button>
  )

  const isVehicleInFilter = (vehicle?: Vehicle): boolean => {
    if (bodyFilter == undefined) {
      return true
    } else {
      return bodyFilter === vehicle?.Body
    }
  }

  const vehiclesToRender = data
    ?.filter(v => v !== null && v !== undefined)
    ?.filter(isVehicleInFilter)
    ?.sort(
      (a, b) =>
        a?.TypeClass?.localeCompare(b && b.TypeClass ? b.TypeClass : "") || -1
    )

  return data ? (
    <>
      <G className="mt-xl">
        <R>
          <C>
            <Title
              text="Model Overview"
              subtext="Mercedes-AMG Models"
              useDefaultAnimation={true}
              animationDelayOffset={50}
            />
          </C>
        </R>
      </G>
      <G className="mt-s">
        <R>
          <C>
            <div 
                data-aos="fade" className="flex flex-row flex-wrap items-center">
              <label
                className="mt-xxs mr-xs flex flex-row items-center text-wb-color-white"
              >
                <svg
                  className="h-3"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 29.96"
                >
                  <path d="M32 0H0v3.48l12.86 12.121v8.972l6.275 5.387V15.578L31.992 3.48 32 0z" />
                </svg>
                <span className="inline-block ml-xxs">Filter</span>
              </label>
              <Pill
                displayName="All"
                type={undefined}
                isActive={bodyFilter === undefined}
              />
              {bodyTypes.map((t, i) => (
                <Pill key={i} {...t} isActive={t.type === bodyFilter} />
              ))}
            </div>
          </C>
        </R>
      </G>
      <G className="mt-m">
        <R>
          {vehiclesToRender?.map((v, i) => (
            <C
              data-aos="fade-up"
              // Random key to force repaint so we trigger animation
              key={Math.random()}
              largeDesktopSize={3}
              desktopSize={4}
              tabletSize={4}
              smallTabletSize={6}
              mobileSize={12}
              className="px-xxs transition-all transition-1/5 hover:bg-wb-color-obsidian"
            >
              {
                <ModelCard
                  {...v}
                  showTypeClass={
                    i === 0 ||
                    vehiclesToRender[i - 1]?.TypeClass !==
                      vehiclesToRender[i]?.TypeClass
                  }
                />
              }
            </C>
          ))}
        </R>
      </G>
    </>
  ) : null
}

export default ModelOverview

const getBodyTypeArray = () => {
  let bodyTypes: {
    displayName: string
    type: BodyType
  }[] = []
  for (const body in BodyType) {
    if (BodyType.hasOwnProperty(body)) {
      bodyTypes.push({
        displayName: prettifyBodyType(body as BodyType),
        type: body as BodyType,
      })
    }
  }
  return bodyTypes
}
