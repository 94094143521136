import {
  ComponentLayoutTwoColumnImageFirst,
  ENUM_COMPONENTLAYOUTTWOCOLUMNIMAGEFIRST_IMAGEFIRSTIMAGESIZE as Size,
} from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { G, R, C } from "../../Workbench/Components"
import { title } from "process"
import Title from "../../generic/Text/Title"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import DoubleCTA from "../../generic/AnyLink/DoubleCTA"
import YouTubeOrImage from "../../generic/YouTubeOrImage/YouTubeOrImage"
import TitleLayout from "../../generic/Text/TitleLayout"
import { WBColorStyle } from "../../../types/Colours"

const TwoColumnImageFirst: FunctionComponent<Partial<
  ComponentLayoutTwoColumnImageFirst
>> = ({
  Title: title,
  Text,
  Image,
  CTAPrimary,
  CTASecondary,
  ImageFirstImageSize,
  HasSpacing = true,
  YouTubeUrl,
  course_types,
  InstructorProfile,
}) => {
  let largeDiff: number
  let smallDiff: number

  switch (ImageFirstImageSize) {
    case Size.Medium:
      largeDiff = 1
      smallDiff = 0
      break

    case Size.Large:
      largeDiff = 2
      smallDiff = 1
      break

    default:
      largeDiff = 0
      smallDiff = 0
      break
  }

  return (
    <G className={HasSpacing ? "mt-xl" : "mt-s"}>
      <R>
        <C
          desktopSize={6 + largeDiff}
          tabletSize={6}
          smallTabletSize={12}
          mobileSize={12}
        >
          <div data-aos="fade">
            <YouTubeOrImage
              youTube={{
                url: YouTubeUrl,
                className: "w-full h-auto object-contain",
              }}
              image={{
                shouldAutoPlay: false,
                shouldBeMuted: false,
                shouldLoop: false,
                shouldShowControls: true,
                ...Image,
                className: "w-full h-auto object-contain",
              }}
            />
            <div className="flex flex-col items-center">
              {InstructorProfile ? (
                <div className="w-full bg-wb-color-amg transform -skew-x-1/13">
                  <h4 className="wb2-type-heading-xs text-wb-color-white text-center py-3xs transform skew-x-1/13">
                    {InstructorProfile}
                  </h4>
                </div>
              ) : null}
              <div className="-ml-3xs flex self-start">
                {course_types
                  ? course_types.map(c => (
                      <StrapiImage
                        {...c?.CourseLogoMainSlider}
                        className="max-w-25"
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </C>
        <C
          desktopSize={6 - largeDiff}
          tabletSize={6}
          smallTabletSize={12}
          mobileSize={12}
          className="mt-xs sm:mt-0"
        >
          {title ? (
            <TitleLayout
              {...title}
              textColor={WBColorStyle.White}
              useDefaultAnimation={true}
            />
          ) : null}
          <ParagraphGroup text={Text} useAnimation={true} />
          <div>
            <DoubleCTA
              primary={CTAPrimary}
              secondary={CTASecondary}
              useDefaultAnimation={true}
            />
          </div>
        </C>
      </R>
    </G>
  )
}

export default TwoColumnImageFirst

export const TwoColumnImageFirstFragment = graphql`
  fragment TwoColumnImageFirstFragment on StrapiGQLSource_ComponentLayoutTwoColumnImageFirst {
    __typename
    _id
    id
    AnchorId
    Text
    ImageFirstImageSize
    HasSpacing
    YouTubeUrl
    Image {
      ...ImageComponentFragment
    }
    Title {
      ...TitleComponentFragment
    }
    CTAPrimary {
      ...NavigationLinkFragment
    }
    CTASecondary {
      ...NavigationLinkFragment
    }
    course_types {
      CourseLogoMainSlider {
        ...ImageComponentFragment
      }
    }
    InstructorProfile
  }
`
