import { FunctionComponent } from "react"
import React from "react"
export interface InputBoxProps<T = any> {
  type?: "text" | "email" | "number" | "tel"
  name?: string
  label?: string
  value?: T
  hasError?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  error?: string
  onChange: (newValue?: T) => void
}

const InputBox: FunctionComponent<InputBoxProps> =({
  type = "text",
  name = "",
  label = "",
  value = "",
  onChange,
  isRequired,
  isDisabled = false,
  hasError,
  error
}) => {
  return (
    <>
      <label
        className={
          "block text-wb-color-white text-sm font-demi" +
          (hasError ? " text-wb-color-error" : "")
        }
      >
        {label}{isRequired? <span className="text-wb-color-amg">*</span> : null}
      </label>
      <input
        className={
          "block w-full mt-xxs p-xxs border border-solid border-wb-color-nobel focus:border-wb-color-black focus:outline-none" +
          (hasError ? " border-2 border-wb-color-amg focus:border-wb-color-amg" : "")
        }
        type={type}
        name={name}
        value={value}
        onChange={e => onChange(e?.target?.value)}
        disabled={isDisabled}
      />
      {hasError ? (
        <span className="block mt-xxs text-wb-color-error text-sm">{error}</span>
      ) : null}
    </>
  )
}

export default InputBox
