import { ComponentLayoutTabs } from "../../../types/generated/strapi"
import React, { FunctionComponent, useState } from "react"
import { graphql } from "gatsby"
import Tab from "./Tab"
import Title from "../../generic/Text/Title"
import { WBFontStyle } from "../../../types/Fonts"
import { G, R, C } from "../../Workbench/Components"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import Prefetch from "../../generic/Prefetch/Prefetch"
import TitleLayout from "../../generic/Text/TitleLayout"

const Tabs: FunctionComponent<Partial<ComponentLayoutTabs>> = ({
  Title: title,
  Tabs: tabs,
}) => {
  const [activeTabKey, setActiveTabKey] = useState<number>(0)

  const tabArray = tabs?.map((t, i) => (
    <Tab key={i} {...t} isVisible={i === activeTabKey} />
  ))

  return (
    <div className="mt-xl">
      {title ? (
        <G>
          <R>
            <C desktopSize={12} tabletSize={12} mobileSize={12}>
              <TitleLayout
                {...title}
                useDefaultAnimation={true}
              />
            </C>
          </R>
        </G>
      ) : null}

      {tabs?.length ? (
        <G className="mt-m">
          <R>
            <C>
              {tabs.map((t, i) => {
                // prefetch images as they may not be initially rendered
                // this is because the content for the current tab is not in DOM
                if (t?.Image?.url)
                  return <Prefetch href={t.Image.url} key={i} />
              })}
              <div className="flex flex-row box-border flex-no-wrap overflow-x-auto">
                {tabs.map((t, i) => (
                  <button
                    key={i}
                    onClick={() => setActiveTabKey(i)}
                    className={
                      "px-xs py-3xs inline-block text-wb-color-white font-demi sm:border-b-2 sm:border-solid hover:bg-wb-color-obsidian focus:bg-wb-color-obsidian focus:outline-none whitespace-no-wrap " +
                      (i == activeTabKey
                        ? "text-wb-color-amg sm:text-wb-color-white sm:border-wb-color-amg"
                        : "sm:border-wb-color-obsidian")
                    }
                  >
                    {t?.Name}
                  </button>
                ))}
                <div className="flex-grow sm:border-b-2 border-solid sm:border-wb-color-obsidian"></div>
              </div>
            </C>
          </R>
        </G>
      ) : null}

      <G className="mt-m">
        <R>
          <C desktopSize={12} tabletSize={12} mobileSize={12}></C>
        </R>
      </G>
      {tabArray?.length ? tabArray : null}
    </div>
  )
}

export default Tabs

export const TabsFragment = graphql`
  fragment TabsFragment on StrapiGQLSource_ComponentLayoutTabs {
    __typename
    _id
    id
    AnchorId
    Title {
      ...TitleComponentFragment
    }
    Tabs {
      ...TabFragment
    }
  }
`
