import { FunctionComponent } from "react"
import React from "react"

export type YouTubeProps = {
  url?: string
  className?: string
}

const YouTube: FunctionComponent<YouTubeProps> = ({ url, className = "" }) => (
  <div className={`relative h-0 pb-7/12 ${className}`}>
    <iframe
      className="absolute top-0 left-0 h-full w-full"
      src={url}
      frameBorder="0"
    />
  </div>
)

export default YouTube
