import React, { FunctionComponent } from "react"
import { ComponentContentLinkBlock } from "../../../types/generated/strapi"

import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import { graphql } from "gatsby"
import ResponsiveLink, {
  LinkDisplay,
} from "../../generic/AnyLink/ResponsiveLink"

const LinkBlock: FunctionComponent<Partial<ComponentContentLinkBlock>> = ({
  Description,
  Title,
  Subtitle,
  Image: image,
  Link,
}) => {
  return (
    <AnyLink
      to={Link?.Link}
      linkStyle={LinkStyle.Unstyled}
      clickClass={Link?.ClickClassDesktop || Link?.ClickClassMobile || ""}
      className="block text-wb-color-white"
    >
      <div className="relative pb-2/3">
        <StrapiImage
          shouldAutoPlay={true}
          shouldBeMuted={true}
          shouldLoop={true}
          shouldShowControls={false}
          className="absolute h-full w-full object-cover"
          {...image}
        />
      </div>
      {Title ? <div className="mt-xs text-2xl font-serif">{Title}</div> : null}
      {Subtitle ? (
        <div className="mt-xxs text-l font-sans font-semibold">{Subtitle}</div>
      ) : null}
      {Description ? (
        <div className="mt-xs font-sans">{Description}</div>
      ) : null}
      {Link?.TextDesktop ? (
        <ResponsiveLink
          to={Link?.Link || ""}
          linkTextDesktop={Link?.TextDesktop}
          linkTextMobile={Link?.TextMobile}
          linkStyle={LinkStyle.WhiteWithChevron}
          desktopVisibleStyle={LinkDisplay.inlineBlock}
          mobileVisibleStyle={LinkDisplay.inlineBlock}
          className="mt-xs"
        />
      ) : null}
    </AnyLink>
  )
}

export default LinkBlock

export const LinkBlockComponentFragment = graphql`
  fragment LinkBlockComponentFragment on StrapiGQLSource_ComponentContentLinkBlock {
    id
    __typename
    Title
    Subtitle
    Description
    Image {
      ...ImageComponentFragment
    }
    Link {
      ...NavigationLinkFragment
    }
  }
`
