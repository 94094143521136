import React, { FunctionComponent, useState } from "react"
import { graphql } from "gatsby"
import { GenericPage as GenericPageModel } from "../../../types/generated/strapi"
import Layout from "../../Layout"
import resolveContentType from "./resolveContentType"
import StrapiComponent from "../../../types/StrapiComponent"
import { pushEvent } from "../../../utils/dataLayer"
import SEO from "../../seo"
import ErrorBoundary from "../../ErrorBoundary"
import setCsrefCookie from "../../../types/setCsrefCookie"
// @ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Paragraph from "../../sections/Paragraph/Paragraph"
import { ParagraphGroup } from "../../sections/Paragraph/ParagraphGroup"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import Title from "../../generic/Text/Title"

type GenericPageQueryResult = {
  data: {
    site: {
      siteMetadata: {
        author?: string
        description?: string
        title?: string
        environment?: string
      }
    }
    strapiGqlSource: {
      genericPage: GenericPageModel
    }
  }
}
const GenericPage: FunctionComponent<GenericPageQueryResult> = (
  props,
  location
) => {
  const siteData = props?.data?.site?.siteMetadata
  const environment = siteData?.environment || "development"

  const pageData = props?.data?.strapiGqlSource?.genericPage
  const title = pageData?.Title || siteData?.title

  pushEvent({
    event: "pageMetaData",
    page: {
      title,
      environment,
      country: "ZA",
      language: "EN",
    },
  })

  setCsrefCookie()

  return (
    <>
      <ErrorBoundary
        fallback={
          <p className="wb-color-white p-m text-center">
            There was an error displaying the page. You may be using an
            unsupported browser
          </p>
        }
      >
        <SEO
          title={title}
          description={pageData?.Description || siteData?.description || ""}
          author={siteData?.author || ""}
          keywords={pageData?.Keywords || ""}
        />
        <Breadcrumb location={location} crumLabel={pageData?.Title} />
        <Layout>
          {pageData?.Sections?.map((sectionData, index) => (
            <ErrorBoundary key={index} fallback={null}>
              <section id={sectionData?.AnchorId || sectionData?.id}>
                {resolveContentType(sectionData as StrapiComponent)}
              </section>
            </ErrorBoundary>
          ))}
        </Layout>
      </ErrorBoundary>
    </>
  )
}

export default GenericPage

export const query = graphql`
  query($id: ID!) {
    site {
      siteMetadata {
        author
        description
        title
        environment
      }
    }
    strapiGqlSource {
      genericPage(id: $id) {
        ...GenericPageFragment
      }
    }
  }
`

export const GenericPageFragment = graphql`
  fragment GenericPageFragment on StrapiGQLSource_GenericPage {
    id
    __typename
    Title
    Slug
    Description
    Keywords
    Sections {
      ... on StrapiGQLSource_ComponentLayoutVehicleOverview {
        ...VehicleOverviewComponentFragment
      }
      ... on StrapiGQLSource_ComponentLayoutCourseInformation {
        ...CourseInformationComponentFragment
      }
      ... on StrapiGQLSource_ComponentLayoutModelOverview {
        ...ModelOverviewFragment
      }
      ... on StrapiGQLSource_ComponentLayoutVoucherForm {
        ...VoucherFormLayoutFragment
      }
      ... on StrapiGQLSource_ComponentLayoutBookingForm {
        ...BookingFormLayoutFragment
      }
      ... on StrapiGQLSource_ComponentLayoutContactForm {
        ...ContactFormLayoutFragment
      }
      ... on StrapiGQLSource_ComponentLayoutLinkBlockGroup {
        ...LinkBlockGroupComponentFragment
      }
      ... on StrapiGQLSource_ComponentLayoutAvailableCourseListing {
        ...AvailableCourseListingFragment
      }
      ... on StrapiGQLSource_ComponentLayoutSliderGroup {
        ...SliderGroupFragment
      }
      ... on StrapiGQLSource_ComponentLayoutDiagonalSlider {
        ...DiagonalSliderFragment
      }
      ... on StrapiGQLSource_ComponentLayoutParagraph {
        ...ParagraphFragment
      }
      ... on StrapiGQLSource_ComponentLayoutTwoColumnImageFirst {
        ...TwoColumnImageFirstFragment
      }
      ... on StrapiGQLSource_ComponentLayoutTwoColumnTextFirst {
        ...TwoColumnTextFirstFragment
      }
      ... on StrapiGQLSource_ComponentLayoutTwoColumnTextOnly {
        ...TwoColumnTextOnlyFragment
      }
      ... on StrapiGQLSource_ComponentLayoutImageGroup {
        ...ImageGroupFragment
      }
      ... on StrapiGQLSource_ComponentLayoutTabs {
        ...TabsFragment
      }
      ... on StrapiGQLSource_ComponentLayoutImageGrid {
        ...ImageGridFragment
      }
      ... on StrapiGQLSource_ComponentLayoutCtaGroup {
        ...CtaGroupFragment
      }
      ... on StrapiGQLSource_ComponentLayoutVehicleSpecificationsCounter {
        ...VehicleSpecsCounterFragment
      }
    }
  }
`

export const SectionSizeFragment = graphql`
  fragment SectionSizeFragment on StrapiGQLSource_ComponentLayoutSectionSize {
    id
    __typename
    DesktopSpan
    TabletSpan
    MobileSpan
  }
`
