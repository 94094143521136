import React, { FunctionComponent } from "react"
import {
  ComponentLayoutCourseInformation,
  ENUM_COURSETYPE_DURATION as Duration,
  CourseType,
} from "../../../types/generated/strapi"

import { G, R, C, P } from "../../Workbench/Components"
import Title from "../../generic/Text/Title"
import { WBColorBGStyle, WBColorStyle } from "../../../types/Colours"
import { WBFontStyle } from "../../../types/Fonts"
import Table, { HeadingPosition } from "../../generic/Table/Table"
import { graphql } from "gatsby"
import formatPrice from "../../../types/formatPrice"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"

const timeToString = (time?: string): string => {
  const matches = time?.match(/\d\d:\d\d/)
  return matches && matches?.length > 0 ? matches[0] : ""
}

const durationToString = (duration?: Duration): string =>
  duration == Duration.HalfDay ? "Half Day" : "Full Day"

const CourseInformation: FunctionComponent<Partial<
  ComponentLayoutCourseInformation
>> = ({ CourseType }) => {
  const duration = durationToString(CourseType?.Duration)

  const headers = CourseType?.Agenda?.map(item => timeToString(item?.Time))
  const agenda = CourseType?.Agenda?.map(item => [item?.Description || ""])

  return (
    <div className="text-wb-color-white bg-wb-color-black mt-xl">
      <G>
        <R>
          <C desktopSize={6}>
            <Title
              text={CourseType?.Name}
              subtext="Overview"
              useDefaultAnimation={true}
            />
            <ul
              className={`flex flex-col md:flex-row flex-wrap md:items-center mt-m ${WBFontStyle.CopySecondary}`}
            >
              {CourseType?.Venue ? (
                <li
                  data-aos="fade-right"
                  className="inline-flex flex-row items-center md:mr-s list-none whitespace-no-wrap"
                >
                  <svg
                    className="h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 58.042 80.432"
                  >
                    <path
                      d="M36.989 56.928l-5.236 23.504h-5.347l-5.235-23.504C8.806 53.363.006 41.667.006 28.969-.328 13.708 13.373-.328 29.08.006c15.595 0 28.962 13.367 28.962 28.962 0 12.588-8.8 24.395-21.053 27.96zm11.584-27.96c0-10.694-8.8-19.494-19.494-19.494-10.582 0-19.605 8.8-19.605 19.494 0 10.582 9.023 19.605 19.605 19.605 10.694.001 19.494-9.022 19.494-19.605zm-15.038 0c0 2.562-1.894 4.456-4.456 4.456s-4.456-1.894-4.456-4.456 1.894-4.456 4.456-4.456c2.563.001 4.456 1.894 4.456 4.456z"
                      fill="#FFF"
                    />
                  </svg>
                  <div className="ml-xxs">{CourseType?.Venue}</div>
                </li>
              ) : null}
              {CourseType?.Price ? (
                <li
                  data-aos="fade-right"
                  className="flex flex-row items-center md:mr-s list-none whitespace-no-wrap"
                >
                  <div className="font-demi">R</div>{" "}
                  <div className="ml-xxs">{formatPrice(CourseType?.Price)}</div>
                </li>
              ) : null}
              {duration ? (
                <li
                  data-aos="fade-right"
                  className="inline-flex flex-row items-center list-none whitespace-no-wrap"
                >
                  <svg
                    className="h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 59.718 59.606"
                  >
                    <path
                      d="M59.712 29.747c.334 16.152-13.701 30.188-29.853 29.853C13.707 59.934-.328 45.899.006 29.747-.328 13.818 13.707-.329 29.859.006c16.152-.335 30.187 13.812 29.853 29.741zM25.626 11.813v24.061h19.828v-5.013L30.75 29.747V11.813h-5.124z"
                      fill="#FFF"
                    />
                  </svg>
                  <div className="ml-xxs">{duration}</div>
                </li>
              ) : null}
            </ul>
            {CourseType?.Description ? (
              <ParagraphGroup
                text={CourseType.Description}
                useAnimation={true}
              />
            ) : null}
          </C>
          <C desktopSize={6}>
            <Title
              className="hidden-small"
              text="Course Itinerary"
              textStyle={WBFontStyle.HeadingM}
              subtext={durationToString(CourseType?.Duration)}
              useDefaultAnimation={true}
              animationDelayOffset={100}
            />
            {headers && agenda ? (
              <Table
                className="mt-s"
                headingPosition={HeadingPosition.Left}
                headings={headers}
                rows={agenda}
                useAnimation={true}
              ></Table>
            ) : null}
          </C>
        </R>
      </G>
    </div>
  )
}

export default CourseInformation

export const CourseInformationComponentFragment = graphql`
  fragment CourseInformationComponentFragment on StrapiGQLSource_ComponentLayoutCourseInformation {
    id
    __typename
    AnchorId
    CourseType {
      Description
      Duration
      Name
      Price
      Venue
      IsActive
      Slug
      Agenda {
        Description
        Time
        id
      }
    }
  }
`
