import { FunctionComponent } from "react"
import {
  Course as CourseModel,
  CourseType as CourseTypeModel,
  Vehicle,
  ENUM_VEHICLE_BODY,
} from "../../../types/generated/strapi"
import { graphql } from "gatsby"
import React from "react"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import AnyLink, { LinkStyle } from "../../generic/AnyLink/AnyLink"
import formatPrice from "../../../types/formatPrice"
import extractUnit from "../../../types/extractUnit"

import "./ModelCard.css"
import { prettifyBodyType } from "./ModelOverview"

export interface ModelCardProps extends Vehicle {
  showTypeClass?: boolean
}

const ModelCard: FunctionComponent<ModelCardProps> = ({
  Name,
  Power,
  Price,
  Torque,
  Image,
  PageSlug,
  Body,
  TypeClass,
  showTypeClass,
}) => {
  const torqueData = extractUnit(Torque)
  const powerData = extractUnit(Power)

  return (
    <article
      data-aos="fade-fade"
      className="model-card relative h-full w-full py-xxs"
    >
      {showTypeClass === true ? (
        <div className="absolute h-40 w-10 border-wb-color-obsidian border-solid border-l border-t"></div>
      ) : null}
      <div className="mt-xs ml-xs text-wb-color-silver font-serif text-5xl">
        {showTypeClass === true && TypeClass ? TypeClass : <br />}
      </div>
      <AnyLink to={PageSlug || "#"} linkStyle={LinkStyle.Unstyled}>
        <StrapiImage {...Image} className="h-full w-full" />
        <div className="mt-xxs text-center text-wb-color-silver">
          {Body ? prettifyBodyType(Body) : <br />}
        </div>
        <div className="mt-3xs text-center text-wb-color-silver text-sm">
          {Price ? `FROM R ${formatPrice(Price)}` : <br />}
        </div>
        <div data-aos-delay="50" className="flex flex-row mt-xs items-center">
          <div className="flex-grow text-wb-color-white">{Name || <br />}</div>
          <div data-aos="fade-right" className="flex-grow-0 flex flex-row">
            <svg
              className="model-card__button-triangle flex-grow-0 text-wb-color-obsidian transition-1/5"
              viewBox="0 0 1 1"
              preserveAspectRatio="none"
              height="44"
            >
              <polygon points="0,1 1,1 1,0" className="fill-current" />
            </svg>
            <button className="model-card__button flex-grow-0 flex flex-row items-center w-full h-full px-2 py-1 text-wb-color-white no-underline bg-wb-color-obsidian transition-1/5 hover:shadow-default hover:bg-wb-color-amg outline-none focus:outline-none">
              <div className="flex-grow-0 flex flex-col items-end">
                <span className="block text-sm">
                  {powerData?.num || <br />}
                </span>
                <span className="block">{torqueData?.num || <br />}</span>
              </div>
              <div className="ml-3xs flex-grow flex flex-col items-start">
                <span className="block text-sm">
                  {powerData?.unit || <br />}
                </span>
                <span className="block">{torqueData?.unit || <br />}</span>
              </div>
              <svg
                className="flex-grow-0 ml-3xs h-3"
                fill="currentColor"
                viewBox="0 0 20 33.5"
              >
                <path d="M0 0h5.223l14.769 16.87L5.343 33.5H.12l12.908-16.63z" />
              </svg>
            </button>
          </div>
        </div>
      </AnyLink>
    </article>
  )
}

export default ModelCard

export const VehicleFragment = graphql`
  fragment VehicleFragment on StrapiGQLSource_Vehicle {
    __typename
    _id
    id
    PageSlug
    Acceleration
    Body
    Engine
    EngineDescription
    Gearbox
    Name
    Power
    Price
    TopSpeed
    Torque
    TypeClass
    Image {
      ...TracedImageComponentFragment
    }
  }
`
