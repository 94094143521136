import { FunctionComponent } from "react"
import React from "react"
import "./Form.css"

export interface RadioGroupProps<T = any> {
  value?: T
  options?: { name?: string; label?: string; value: T }[]
  hasError?: boolean
  isHorizontal?: boolean
  isDisabled?: boolean
  error?: string
  onChange: (newValue?: T) => void
}

const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  onChange,
  hasError,
  isHorizontal,
  isDisabled = false,
  error,
  options,
  value,
}) => {
  const handleSelect = (newValue: any) => {
    if (!isDisabled) onChange(newValue)
  }

  return (
    <>
      <div
        className={
          "flex flex-wrap" + (isHorizontal === true ? " flex-row" : " flex-col")
        }
      >
        {options?.map((o, i) => (
          <div
            key={i}
            className={
              "flex flex-row items-center mr-xs" +
              (i === options.length ? "" : " mt-xxs")
            }
            onClick={() => handleSelect(o.value)}
          >
            <div className="radio flex-grow-0">
              <input
                type="radio"
                name={o.label}
                value={o.value}
                checked={o.value === value}
                onChange={() => handleSelect(o.value)}
                onKeyPress={() => handleSelect(o.value)}
                disabled={isDisabled}
              />
              <span className="radio__checkmark"></span>
            </div>
            <div className="flex-grow">
              <label className={"block text-wb-color-white"} htmlFor={o.name}>
                {o.label}
              </label>
            </div>
          </div>
        ))}
      </div>
      {hasError ? (
        <span className="block mt-xxs text-wb-color-error text-sm">
          {error}
        </span>
      ) : null}
    </>
  )
}

export default RadioGroup
