import { FunctionComponent } from "react"
import React from "react"
import "./Form.css"

const AnimatedEllipse: FunctionComponent = () => (
  <span className="animated-ellipse">
    <span className="animated-ellipse__dot-1"> .</span>
    <span className="animated-ellipse__dot-2"> .</span>
    <span className="animated-ellipse__dot-3"> .</span>
  </span>
)

export default AnimatedEllipse
