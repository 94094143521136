import React, { FunctionComponent } from "react"
import {
  Legal,
  ComponentLayoutVoucherForm,
} from "../../../types/generated/strapi"
import { useStaticQuery, graphql } from "gatsby"
import VoucherForm from "../../sections/VoucherForm/VoucherForm"

interface VoucherFormData {
  site: {
    siteMetadata: {
      formApiBaseUrl?: string
    }
  }
  strapiGqlSource: {
    legal?: Partial<Legal>
  }
}

const VoucherFormLayout: FunctionComponent<ComponentLayoutVoucherForm> = () => {
  const data: VoucherFormData = useStaticQuery(graphql`
    query VoucherFormQuery {
      site {
        siteMetadata {
          formApiBaseUrl
        }
      }
      strapiGqlSource {
        legal {
          MarketingConsent
        }
      }
    }
  `)

  return (
    <VoucherForm
      formApiBaseUrl={data?.site?.siteMetadata?.formApiBaseUrl}
      marketingConsentText={data?.strapiGqlSource?.legal?.MarketingConsent}
    />
  )
}

export default VoucherFormLayout

export const VoucherFormLayoutFragment = graphql`
  fragment VoucherFormLayoutFragment on StrapiGQLSource_ComponentLayoutVoucherForm {
    __typename
    _id
    id
    AnchorId
  }
`
