import React, { FunctionComponent, useState } from "react"
import {
  ComponentLayoutLinkBlockGroup,
  ComponentContentLinkBlock,
} from "../../../types/generated/strapi"

import { graphql } from "gatsby"
import Title from "../../generic/Text/Title"
import { G, R, C, P } from "../../Workbench/Components"
import Slider from "../../generic/Slider/Slider"
import LinkBlock from "./LinkBlock"
import { ReactIdSwiperProps } from "react-id-swiper"
import useWindowSize, {
  convertSizeInPxToNumber,
} from "../../../hooks/useWindowSize"
import ResponsiveSlider from "../../generic/Slider/ResponsiveSlider"

const LinkBlockGroup: FunctionComponent<Partial<
  ComponentLayoutLinkBlockGroup
>> = ({ Title: title = {}, Links = [] }) => {
  let slides: JSX.Element[] = []

  Links?.forEach((link, i) => {
    if (link != null)
      slides.push(
        <div
          key={i}
          data-aos="fade-up"
          data-aos-delay={i * 100}
          className="w-full"
        >
          <LinkBlock {...link} />
        </div>
      )
  })

  return (
    <ResponsiveSlider
      title={title?.Text}
      subtitle={title?.Subtext}
      useTitleAnimation={true}
      slides={slides}
    />
  )
}

export default LinkBlockGroup

export const LinkBlockGroupComponentFragment = graphql`
  fragment LinkBlockGroupComponentFragment on StrapiGQLSource_ComponentLayoutLinkBlockGroup {
    id
    __typename
    AnchorId
    Links {
      ...LinkBlockComponentFragment
    }
    SectionSize {
      ...SectionSizeFragment
    }
    Title {
      ...TitleComponentFragment
    }
  }
`
