import { useState, useEffect } from "react"

/**
 * Window size with dimensions in px, e.g. 100 or "100px"
 */
export interface WindowDimensions {
  height?: number
  width?: number
}

/**
 * React hook for reacting to window size
 * from [here](https://usehooks.com/useWindowSize/)
 * Only works on the Client
 * @example
 * const {height, width} = useWindowSize();
 */
const useWindowSize = (): WindowDimensions => {
  const isClient = typeof window === "object"

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return () => {}
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

/**
 * Convert a string like "12px" to 12
 * @param size dimensions as string in px
 */
export const convertSizeInPxToNumber = (size: string): number => {
  const cleanString = size.trim().replace(/px/g, "")
  return +cleanString
}

export default useWindowSize
