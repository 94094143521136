import {
  ComponentLayoutImageGroup,
  Maybe,
  ComponentContentImageOrVideo,
} from "../../../types/generated/strapi"

import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { G, R, C } from "../../Workbench/Components"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import { resolveLayout } from "./ResolveLayout"
import { ReactIdSwiperProps } from "react-id-swiper"
import YouTube from "../../generic/Youtube/YouTube"
import ResponsiveSlider from "../../generic/Slider/ResponsiveSlider"

const sliderParams: ReactIdSwiperProps = {
  // containerClass: "", // this allows for the overflow
  spaceBetween: 0,
  // slidesPerView: 1,
  slidesPerGroup: 1,
  loop: false,
  // autoplay: {
  //   disableOnInteraction: true,
  //   delay: 3500,
  // },
  effect: "slide",
  // fadeEffect: {
  //   crossFade: true
  // },
  // speed: 500,
}

const ImageGroup: FunctionComponent<Partial<ComponentLayoutImageGroup>> = ({
  FirstImageOrVideo,
  SecondImageOrVideo,
  ThirdImageOrVideo,
  FourthImageOrVideo,
}) => {
  let images: Maybe<ComponentContentImageOrVideo>[] = [
    FirstImageOrVideo,
    SecondImageOrVideo,
    ThirdImageOrVideo,
    FourthImageOrVideo,
  ]

  images = images.filter(image => image && (image.Image || image?.YouTubeUrl))

  const videoConfig = {
    shouldAutoPlay: false,
    shouldBeMuted: false,
    shouldLoop: false,
    shouldShowControls: true,
  }

  return (
    <>
      <div className="sm:hidden mt-xl">
        <ResponsiveSlider
          slides={images.map((image, i) => (
            <div key={i} data-aos="fade-up" data-aos-delay={i * 100}>
              {image?.YouTubeUrl ? (
                <YouTube url={image.YouTubeUrl} className="mb-xs" />
              ) : (
                <StrapiImage
                  {...videoConfig}
                  {...image?.Image}
                  className="mb-xs w-full"
                />
              )}
            </div>
          ))}
        />
      </div>

      <G className="mt-xl sm:block hidden">
        {images.length > 0 ? (
          <R className="sm:relative sm:overflow-hidden">
            {images[0] ? (
              <C
                desktopSize={resolveLayout(images.length)?.leftSize?.desktop}
                tabletSize={resolveLayout(images.length)?.leftSize?.tablet}
                mobileSize={12}
              >
                <div data-aos="fade">
                  {images[0]?.YouTubeUrl ? (
                    <YouTube url={images[0].YouTubeUrl} className="mb-xs" />
                  ) : (
                    <StrapiImage
                      {...videoConfig}
                      {...images[0]?.Image}
                      className="mb-xs w-full"
                    />
                  )}
                </div>
              </C>
            ) : null}

            {images[1] ? (
              <C
                desktopSize={resolveLayout(images.length)?.rightSize?.desktop}
                tabletSize={resolveLayout(images.length)?.rightSize?.tablet}
                mobileSize={12}
                className={`${
                  images.length == 4 ? "sm:absolute sm:bottom-0 sm:right-0" : ""
                } `}
              >
                <div data-aos="fade" data-aos-delay="100">
                  {images[1]?.YouTubeUrl ? (
                    <YouTube url={images[1].YouTubeUrl} className="mb-xs" />
                  ) : (
                    <StrapiImage
                      {...videoConfig}
                      {...images[1]?.Image}
                      className="mb-xs w-full"
                    />
                  )}
                </div>
                {images[2] && !images[3] ? (
                  <div data-aos="fade" data-aos-delay="200">
                    {images[2]?.YouTubeUrl ? (
                      <YouTube
                        url={images[2].YouTubeUrl}
                        className={images.length === 3 ? "" : "sm:w-3/4"}
                      />
                    ) : (
                      <StrapiImage
                        {...videoConfig}
                        {...images[2]?.Image}
                        className={images.length === 3 ? "" : "sm:w-3/4"}
                      />
                    )}
                  </div>
                ) : null}
              </C>
            ) : null}
          </R>
        ) : null}

        {images.length > 3 ? (
          <R>
            {images[2] && images[3] ? (
              <C desktopSize={7} tabletSize={7} mobileSize={12}>
                <div data-aos="fade" data-aos-delay="200">
                  {images[2]?.YouTubeUrl ? (
                    <YouTube
                      url={images[2].YouTubeUrl}
                      className="sm:w-7/12 sm:float-right mb-xs"
                    />
                  ) : (
                    <StrapiImage
                      {...videoConfig}
                      {...images[2]?.Image}
                      className="sm:w-7/12 sm:float-right mb-xs"
                    />
                  )}
                </div>
              </C>
            ) : null}
            {images[3] ? (
              <C desktopSize={5} tabletSize={5} mobileSize={12}>
                <div data-aos="fade" data-aos-delay="300">
                  {images[3]?.YouTubeUrl ? (
                    <YouTube url={images[3].YouTubeUrl} className="sm:w-3/5" />
                  ) : (
                    <StrapiImage
                      {...videoConfig}
                      {...images[3]?.Image}
                      className="sm:w-3/5"
                    />
                  )}
                </div>
              </C>
            ) : null}
          </R>
        ) : null}
      </G>
    </>
  )
}

export default ImageGroup

export const ImageGroupFragment = graphql`
  fragment ImageGroupFragment on StrapiGQLSource_ComponentLayoutImageGroup {
    __typename
    _id
    id
    AnchorId
    FirstImageOrVideo {
      ...ImageOrVideoFragment
    }
    SecondImageOrVideo {
      ...ImageOrVideoFragment
    }
    ThirdImageOrVideo {
      ...ImageOrVideoFragment
    }
    FourthImageOrVideo {
      ...ImageOrVideoFragment
    }
  }
`

export const ImageOrVideoFragment = graphql`
  fragment ImageOrVideoFragment on StrapiGQLSource_ComponentContentImageOrVideo {
    __typename
    _id
    id
    YouTubeUrl
    Image {
      ...ImageComponentFragment
    }
  }
`
