/**
 * Extract the numeric value and unit from the given string.
 * 200km/h => 200, and km/h
 * @param text text with value and unit. e.g 200km/h
 */
const extractUnit = (text?: string): NumWithUnit => {
  const matches = text?.trim()?.match(/(\d*)(.*)/)

  // if (matches?.length && matches.length < 3) {
  return {
    num: (matches && matches[1]?.trim()) || undefined,
    unit: (matches && matches[2]?.trim()) || undefined,
  }
}

export default extractUnit

export type NumWithUnit = {
  num?: string
  unit?: string
}
