import { FunctionComponent, ReactElement } from "react"
import useWindowSize, {
  convertSizeInPxToNumber,
} from "../../../hooks/useWindowSize"
import Tailwind from "../../../styles/tailwind.config.json"
import { ReactIdSwiperProps } from "react-id-swiper"
import React from "react"
import Slider from "./Slider"
import { R, C, G } from "../../Workbench/Components"
import Title from "../Text/Title"

export interface ResponsiveSliderProps {
  title?: string
  subtitle?: string
  slides?: ReactElement[]
  autoResize?: boolean
  useTitleAnimation?: boolean
}

const ResponsiveSlider: FunctionComponent<ResponsiveSliderProps> = ({
  title,
  subtitle,
  slides = [],
  autoResize = true,
  useTitleAnimation = false,
}) => {
  const xSmallBreakpoint = convertSizeInPxToNumber(Tailwind.theme.screens.xs)
  const smallBreakpoint = convertSizeInPxToNumber(Tailwind.theme.screens.sm)
  const mediumBreakpoint = convertSizeInPxToNumber(Tailwind.theme.screens.md)
  const largeBreakpoint = convertSizeInPxToNumber(Tailwind.theme.screens.lg)

  const defaultSlideCount = slides.length > 3 ? 4 : 3

  const bulletRenderer = (index: number, className: string) => {
    return `<span key="${index}" class="${className}"></span>`
  }

  const sliderParams: ReactIdSwiperProps = {
    slidesPerView: defaultSlideCount,
    spaceBetween: 30,
    containerClass: "", // this allows for the overflow
    rebuildOnUpdate: true,
    breakpoints: {
      [largeBreakpoint]: {
        slidesPerView: 4,
        pagination: {
          el: ".swiper__pagination",
          clickable: true,
          bulletActiveClass: "swiper__pagination-bullet--active",
          bulletClass: "swiper__pagination-bullet",
          renderBullet: slides?.length > 4 ? bulletRenderer : undefined,
        },
      },
      [mediumBreakpoint]: {
        slidesPerView: 3,
        pagination: {
          el: ".swiper__pagination",
          clickable: true,
          bulletActiveClass: "swiper__pagination-bullet--active",
          bulletClass: "swiper__pagination-bullet",
          renderBullet: slides?.length > 3 ? bulletRenderer : undefined,
        },
      },
      [smallBreakpoint]: {
        slidesPerView: 2,
        pagination: {
          el: ".swiper__pagination",
          clickable: true,
          bulletActiveClass: "swiper__pagination-bullet--active",
          bulletClass: "swiper__pagination-bullet",
          renderBullet: slides?.length > 2 ? bulletRenderer : undefined,
        },
      },
      [0]: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper__pagination",
          clickable: true,
          bulletActiveClass: "swiper__pagination-bullet--active",
          bulletClass: "swiper__pagination-bullet",
          renderBullet: slides?.length > 1 ? bulletRenderer : undefined,
        },
      },
    },
    pagination: {
      el: ".swiper__pagination",
      clickable: true,
      bulletActiveClass: "swiper__pagination-bullet--active",
      bulletClass: "swiper__pagination-bullet",
      renderBullet:
        slides?.length > defaultSlideCount ? bulletRenderer : undefined,
    },
  }

  return (
    <div className="mt-xl bg-wb-color-black">
      <G>
        <R>
          <C desktopSize={12}>
            {title ? (
              <Title
                text={title}
                subtext={subtitle}
                useDefaultAnimation={useTitleAnimation}
              />
            ) : null}
          </C>
        </R>
      </G>
      <div className="overflow-hidden">
        <G className="mt-l">
          <R>
            <C>
              {slides?.length ? (
                <Slider {...sliderParams}>{slides}</Slider>
              ) : null}
            </C>
          </R>
        </G>
      </div>
    </div>
  )
}

export default ResponsiveSlider
