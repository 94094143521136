import { FunctionComponent, useState } from "react"
import React from "react"
import {
  Titleship,
  ComponentEventSpectator,
} from "../../../types/generated/webApi"
import { G, R, C } from "../../Workbench/Components"
import RadioGroup from "../../generic/Form/RadioGroup"
import InputBox from "../../generic/Form/InputBox"
import { ParagraphGroup } from "../../sections/Paragraph/ParagraphGroup"
import LoadingState from "../../generic/Form/LoadingState"
import isFieldValid, { matchers } from "../../../utils/isFieldValid"
import Paragraph from "../Paragraph/Paragraph"

export interface StepThreeProps {
  value: StepThreeDetails
  shouldShowErrors?: boolean
  onValueChange: (newValue?: StepThreeDetails) => void
  loadingState?: LoadingState
  marketingConsentText?: string
}

export interface StepThreeDetails {
  city?: string
  province?: string
  postalCode?: string
  driversLicense?: string
  spectatorName?: string
  spectatorDietary?: string
  deitary?: string
  voucherNumber?: string
  isValid?: boolean
  marketingConsent?: boolean
}

const StepThree: FunctionComponent<StepThreeProps> = ({
  value,
  shouldShowErrors,
  onValueChange,
  loadingState = LoadingState.Initial,
  marketingConsentText,
}) => {
  const isDisabled = loadingState === LoadingState.IsLoading
  const [marketingConsent, setMarketingConsent] = useState<boolean | undefined>(
    undefined
  )

  const showConsentError = () => {
    const isValid = isFieldValid(matchers.marketingConsent, marketingConsent)
    return !isValid && shouldShowErrors
  }

  const showFieldError = (
    match: RegExp,
    value?: any,
    minLength?: number,
    maxLength?: number
  ) => {
    return (
      shouldShowErrors === true &&
      !isFieldValid(match, value, minLength, maxLength)
    )
  }

  const setMarketing = (marketingConsent?: boolean) => {
    value.marketingConsent = marketingConsent
  }

  const handleFieldChange = (newValue?: StepThreeDetails) => {
    const validations = [
      isFieldValid(matchers.any, newValue?.city),
      isFieldValid(matchers.any, newValue?.province),
      isFieldValid(matchers.postalCode, newValue?.postalCode),
      isFieldValid(matchers.any, newValue?.driversLicense),
    ]

    const isValid =
      validations.filter(isFieldValid => isFieldValid !== true).length === 0

    onValueChange({ ...newValue, isValid })
  }

  return (
    <G>
      <R>
        <C desktopSize={3} tabletSize={4} mobileSize={12} className="mt-xs">
          <InputBox
            label="Province"
            name="Province"
            type="text"
            value={value.province}
            onChange={(province?: string) =>
              handleFieldChange({ ...value, province })
            }
            isRequired={true}
            error="Please enter Province"
            hasError={showFieldError(matchers.any, value.province)}
            isDisabled={isDisabled}
          />
        </C>

        <C desktopSize={3} tabletSize={5} mobileSize={12} className="mt-xs">
          <InputBox
            label="City"
            name="City"
            type="text"
            value={value.city}
            onChange={(city?: string) => handleFieldChange({ ...value, city })}
            isRequired={true}
            error="Please enter City"
            hasError={showFieldError(matchers.any, value.city)}
            isDisabled={isDisabled}
          />
        </C>

        <C desktopSize={2} tabletSize={3} mobileSize={12} className="mt-xs">
          <InputBox
            label="Postal Code"
            name="PostalCode"
            type="text"
            value={value.postalCode}
            onChange={(postalCode?: string) =>
              handleFieldChange({ ...value, postalCode })
            }
            isRequired={true}
            error="Please enter Postal Code"
            hasError={showFieldError(matchers.postalCode, value.postalCode)}
            isDisabled={isDisabled}
          />
        </C>
      </R>

      <R>
        <C desktopSize={4} tabletSize={6} mobileSize={12} className="mt-xs">
          <InputBox
            label="Driver's License"
            name="DriversLicense"
            type="text"
            value={value.driversLicense}
            onChange={(driversLicense?: string) =>
              handleFieldChange({ ...value, driversLicense })
            }
            isRequired={true}
            error="Please enter Driver's License"
            hasError={showFieldError(matchers.any, value.driversLicense)}
            isDisabled={isDisabled}
          />
        </C>
        <C desktopSize={4} tabletSize={6} mobileSize={12} className="mt-xs">
          <InputBox
            label="Dietary Requirements (if any)"
            name="Dierty"
            type="text"
            value={value.deitary}
            onChange={(deitary?: string) =>
              handleFieldChange({ ...value, deitary })
            }
            isRequired={false}
            isDisabled={isDisabled}
          />
        </C>
      </R>

      <R>
        <C desktopSize={3} tabletSize={5} mobileSize={12} className="mt-xs">
          <InputBox
            label="Spectator Name (if you will bring one)"
            name="Spectator"
            type="text"
            value={value.spectatorName}
            onChange={(spectatorName?: string) =>
              handleFieldChange({ ...value, spectatorName })
            }
            isRequired={false}
            isDisabled={isDisabled}
          />
        </C>
        <C desktopSize={3} tabletSize={4} mobileSize={12} className="mt-xs">
          <InputBox
            label="Spectator Dietery "
            name="Spectator"
            type="text"
            value={value.spectatorDietary}
            onChange={(spectatorDietary?: string) =>
              handleFieldChange({ ...value, spectatorDietary })
            }
            isRequired={false}
            isDisabled={isDisabled}
          />
        </C>

        <C desktopSize={2} tabletSize={3} mobileSize={12} className="mt-xs">
          <InputBox
            label="Voucher (if any)"
            name="Voucher"
            type="text"
            value={value.voucherNumber}
            onChange={(voucherNumber?: string) =>
              handleFieldChange({ ...value, voucherNumber })
            }
            isRequired={false}
            isDisabled={isDisabled}
          />
        </C>
      </R>

      <R>
        <C desktopSize={12} tabletSize={12} mobileSize={12} className="mt-xs">
          <ParagraphGroup
            text={marketingConsentText}
            className="text-wb-color-white"
          />
          <div className="mt-xxs">
            <RadioGroup
              value={marketingConsent}
              isHorizontal={true}
              onChange={(marketingConsent?: boolean) => {
                setMarketingConsent(marketingConsent)
                setMarketing(marketingConsent)
              }}
              options={[
                { name: "Yes", label: "Yes", value: true },
                { name: "No", label: "No", value: false },
              ]}
              error="Please select an option"
              hasError={showConsentError()}
              isDisabled={loadingState === LoadingState.IsLoading}
            />
          </div>
          <Paragraph Text="Please note that the price for a spectator is R 690.00" />
        </C>
      </R>
      {loadingState === LoadingState.HasError ? (
        <R>
          <C className="mt-xs">
            <p className="text-wb-color-error leading-7">
              An error occured. Please try again.
            </p>
          </C>
        </R>
      ) : null}
    </G>
  )
}

export default StepThree
