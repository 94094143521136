import { ComponentLayoutCtaGroup } from "../../../types/generated/strapi"
import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { G, R, C } from "../../Workbench/Components"
import ResponsiveLink, {
  LinkDisplay,
} from "../../generic/AnyLink/ResponsiveLink"
import { LinkStyle } from "../../generic/AnyLink/AnyLink"

const CtaGroup: FunctionComponent<Partial<ComponentLayoutCtaGroup>> = ({
  CtaButtons,
}) => {
  return CtaButtons ? (
    <div className="mt-xl bg-wb-color-obsidian py-xs flex flex-col xs:flex-row justify-center flex-wrap">
      {CtaButtons?.map(link => (
        <ResponsiveLink
          key={link?.id}
          to={link?.Link || "#"}
          linkStyle={LinkStyle.WhiteTertiaryButton}
          linkTextDesktop={link?.TextDesktop}
          linkTextMobile={link?.TextMobile}
          clickClassDesktop={link?.ClickClassDesktop}
          clickClassMobile={link?.ClickClassMobile}
          desktopVisibleStyle={LinkDisplay.inlineBlock}
          mobileVisibleStyle={LinkDisplay.inlineBlock}
          className="ml-s mt-xxs mb-xxs whitespace-no-wrap"
        />
      ))}
    </div>
  ) : null
}

export default CtaGroup

export const CtaGroupFragment = graphql`
  fragment CtaGroupFragment on StrapiGQLSource_ComponentLayoutCtaGroup {
    __typename
    _id
    id
    AnchorId
    CtaButtons {
      ...NavigationLinkFragment
    }
  }
`
