import {
  ComponentContentTab,
  ENUM_COMPONENTCONTENTTAB_LAYOUT as LayoutType,
} from "../../../types/generated/strapi"

import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { G, R, C } from "../../Workbench/Components"
import { ParagraphGroup } from "../Paragraph/ParagraphGroup"
import Title from "../../generic/Text/Title"
import { WBFontStyle } from "../../../types/Fonts"
import StrapiImage from "../../generic/StrapiImage/StrapiImage"
import YouTubeOrImage from "../../generic/YouTubeOrImage/YouTubeOrImage"

export interface TabProps extends ComponentContentTab {
  isVisible?: boolean
}

const Tab: FunctionComponent<Partial<TabProps>> = ({
  Title: title,
  Paragraph,
  Image,
  Layout = LayoutType.TextFirst,
  isVisible = true,
  YouTubeUrl,
}) => {
  const videoConfig = {
    shouldBeMuted: true,
    shouldAutoPlay: false,
    shouldShowControls: true,
    shouldLoop: true,
  }

  return isVisible ? (
    <G>
      <R>
        {Layout === LayoutType.TextFirst ? (
          <>
            <C
              desktopSize={6}
              tabletSize={6}
              smallTabletSize={12}
              mobileSize={12}
            >
              <div data-aos="fade">
                {Title ? (
                  <Title
                    text={title}
                    textStyle={WBFontStyle.HeadingM}
                    hasOverline={false}
                  />
                ) : null}
                {Paragraph ? <ParagraphGroup text={Paragraph} /> : null}
              </div>
            </C>
            {Image || YouTubeUrl ? (
              <C
                desktopSize={6}
                tabletSize={6}
                smallTabletSize={12}
                mobileSize={12}
              >
                <div data-aos="fade">
                  <YouTubeOrImage
                    youTube={{
                      url: YouTubeUrl,
                      className: "h-full w-full object-cover",
                    }}
                    image={{
                      className: "h-full w-full object-cover",
                      ...Image,
                      ...videoConfig,
                    }}
                  />
                </div>
              </C>
            ) : null}
          </>
        ) : (
          <>
            {Image || YouTubeUrl ? (
              <C
                desktopSize={6}
                tabletSize={6}
                smallTabletSize={12}
                mobileSize={12}
              >
                <div data-aos="fade">
                  {Title ? (
                    <Title
                      className="sm:hidden"
                      text={title}
                      textStyle={WBFontStyle.HeadingM}
                      hasOverline={false}
                    />
                  ) : null}
                  <YouTubeOrImage
                    youTube={{
                      url: YouTubeUrl,
                      className: "h-full w-full object-cover",
                    }}
                    image={{
                      className: "h-full w-full object-cover",
                      ...Image,
                      ...videoConfig,
                    }}
                  />
                </div>
              </C>
            ) : null}
            <C
              desktopSize={6}
              tabletSize={6}
              smallTabletSize={12}
              mobileSize={12}
            >
              <div data-aos="fade">
                {Title ? (
                  <Title
                    className="hidden sm:block"
                    text={title}
                    textStyle={WBFontStyle.HeadingM}
                    hasOverline={false}
                  />
                ) : null}
                {Paragraph ? <ParagraphGroup text={Paragraph} /> : null}
              </div>
            </C>
          </>
        )}
      </R>
    </G>
  ) : null
}

export default Tab

export const TabFragment = graphql`
  fragment TabFragment on StrapiGQLSource_ComponentContentTab {
    __typename
    _id
    id
    Name
    Title
    Paragraph
    Layout
    YouTubeUrl
    Image {
      ...ImageComponentFragment
    }
  }
`
